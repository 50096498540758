// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/masters'

export const getDonationType = createAsyncThunk('donationType/getDonationType', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllDonationType(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDonationTypeById = createAsyncThunk('donationType/getDonationTypeById', async (id) => {
  const response = await MasterService.getDonationTypeById(id)
  return response
})

export const addDonationType = createAsyncThunk('donationType/addDonationType', async (donationType, { rejectWithValue }) => {
  try {
    const response = await MasterService.addDonationType(donationType)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDonationType = createAsyncThunk('donationType/updateDonationType', async (donationType, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateDonationType(donationType)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteDonationType = createAsyncThunk('donationType/deleteDonationType', async (id, { dispatch, getState }) => {
  await MasterService.deleteDonationType(id)
  await dispatch(getDonationType(getState()?.donationTypes.params))
  return id
})

export const donationTypeSlice = createSlice({
  name: 'donation_type',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDonationType: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDonationType.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDonationTypeById.fulfilled, (state, action) => {
        state.individualDonationType = action.payload
        state.loading = false
      })
      .addCase(getDonationTypeById.pending, (state, action) => {
        state.individualDonationType = action.payload
        state.loading = true
      })
      .addCase(getDonationTypeById.rejected, (state, action) => {
        state.individualDonationType = action.payload
        state.loading = false
      })
  }
})

export default donationTypeSlice.reducer
