import { lazy } from 'react'

const KurimContributionList = lazy(() => import('../../views/kurim-contribution/list/index'))
const MonkSearch = lazy(() => import('../../views/kurim-contribution/add/index'))
const AddKurimContribution = lazy(() => import('../../views/kurim-contribution/add/addKurimContribution'))
const ViewKurimContribution = lazy(() => import('../../views/kurim-contribution/view/index'))
const EditKurimContribution = lazy(() => import('../../views/kurim-contribution/edit/index'))
const AddMoreMonk = lazy(() => import('../../views/kurim/add-more-monk/index'))

const KurimContributionRoutes = [
  {
    path: '/kurim-contribution/search-monk',
    element: <MonkSearch />,
    meta: {
      action: 'create',
      resource: 'KurimContribution'
    }
  },
  {
    path: '/kurim-contribution/search-monk/add',
    element: <AddKurimContribution />,
    meta: {
      action: 'create',
      resource: 'KurimContribution'
    }
  },
  {
    path: '/kurim-contribution/lists',
    element: <KurimContributionList />,
    meta: {
      action: 'read',
      resource: 'KurimContribution'
    }
  },
  {
    path: '/kurim-contribution/lists/view/:id',
    element: <ViewKurimContribution />,
    meta: {
      action: 'read',
      resource: 'KurimContribution'
    }
  },
  {
    path: '/kurim-contribution/lists/edit/:id',
    element: <EditKurimContribution />,
    meta: {
      action: 'update',
      resource: 'KurimContribution'
    }
  },
  {
    path: '/kurim/lists/add-more-monk/:id',
    element: <AddMoreMonk />,
    meta: {
      action: 'create',
      resource: 'KurimContribution'
    }
  }
]

export default KurimContributionRoutes
