import { lazy } from 'react'

const StaffList = lazy(() => import('../../views/staff-information/staff/list'))
const AddStaff = lazy(() => import('../../views/staff-information/staff/add/index'))
const ViewStaff = lazy(() => import('../../views/staff-information/staff/view/index'))
const EditStaff = lazy(() => import('../../views/staff-information/staff/edit/index'))
const DratshangSearch = lazy(() => import('../../views/dratshang-information/search/search'))

const StaffRoutes = [
  {
    path: '/employee/lists',
    element: <StaffList />,
    meta: {
      action: 'read',
      resource: 'Staff'
    }
  },
  {
    path: '/employee/add',
    element: <AddStaff />,
    meta: {
      action: 'create',
      resource: 'Staff'
    }
  },
  {
    path: '/employee/lists/view/:id',
    element: <ViewStaff />,
    meta: {
      action: 'read',
      resource: 'Staff'
    }
  },
  {
    path: '/employee/lists/edit/:id',
    element: <EditStaff />,
    meta: {
      action: 'update',
      resource: 'Staff'
    }
  },
  {
    path: '/dratshang/search',
    element: <DratshangSearch />,
    meta: {
      action: 'read',
      resource: 'Staff'
    }
  }
]

export default StaffRoutes
