import { lazy } from 'react'

const TransactionList = lazy(() => import('../../views/transaction/list/index'))
const TransactionSearch = lazy(() => import('../../views/transaction/add/index'))
const TransactionAdd = lazy(() => import('../../views/transaction/add/AddTransaction'))

const TransactionRoutes = [
  {
    path: '/transaction',
    element: <TransactionList />,
    meta: {
      action: 'read',
      resource: 'Transaction'
    }
  },
  {
    path: '/transaction/search',
    element: <TransactionSearch />,
    meta: {
      action: 'create',
      resource: 'Transaction'
    }
  },
  {
    path: '/transaction/add',
    element: <TransactionAdd />,
    meta: {
      action: 'create',
      resource: 'Transaction'
    }
  }
]

export default TransactionRoutes
