// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MonkService from '@services/monk-information'

export const getAllMonk = createAsyncThunk('monk/getAllMonk', async (params, { rejectWithValue }) => {
  try {
    const response = await MonkService.getAllMonks(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getMonkById = createAsyncThunk('monk/getMonkById', async (id) => {
  const response = await MonkService.getMonkById(id)
  return response
})

export const addMonk = createAsyncThunk('monk/addMonk', async (monk, { rejectWithValue }) => {
  try {
    const response = await MonkService.addMonk(monk)
    return response
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

export const updateMonk = createAsyncThunk('monk/updateMonk', async (monk, { rejectWithValue }) => {
  try {
    const response = await MonkService.updateMonk(monk)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteMonk = createAsyncThunk('monk/deleteMonk', async (id, { dispatch, getState }) => {
  await MonkService.deleteMonk(id)
  await dispatch(getAllMonk(getState()?.monkInformations.params))
  return id
})

export const monkSlice = createSlice({
  name: 'monk',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualMonk: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMonk.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMonkById.fulfilled, (state, action) => {
        state.individualMonk = action.payload
        state.loading = false
      })
      .addCase(getMonkById.pending, (state, action) => {
        state.individualMonk = action.payload
        state.loading = true
      })
      .addCase(getMonkById.rejected, (state, action) => {
        state.individualMonk = action.payload
        state.loading = false
      })
  }
})

export default monkSlice.reducer
