// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MembershipService from '../../../services/membership'

export const getFileUploads = createAsyncThunk('accountFileUpload/getFileUploads', async (params, { rejectWithValue }) => {
  try {
    const response = await MembershipService.getAccountFileUploads(params)
    console.log(response.data)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteFileUploads = createAsyncThunk('accountFileUpload/deleteFileUploads', async (id, { dispatch, getState }) => {
  await MembershipService.deleteFileUploads(id)
  await dispatch(getFileUploads(getState()?.fileUploads.params))
  return id
})

export const FileUploadsSlice = createSlice({
  name: 'fileUploads',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFileUploads.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export default FileUploadsSlice.reducer
