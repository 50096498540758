import { lazy } from 'react'

const DratshangAssetList = lazy(() => import('../../views/dratshang-asset/asset/list/index'))
const AddDratshangAsset = lazy(() => import('../../views/dratshang-asset/asset/add/index'))
const ViewDratshangAsset = lazy(() => import('../../views/dratshang-asset/asset/view/index'))
const EditDratshangAsset = lazy(() => import('../../views/dratshang-asset/asset/edit/index'))
const AssignForAssetCheckOut = lazy(() => import('../../views/dratshang-asset/asset/assign-asset/add/index'))
const AssignForAssetCheckin = lazy(() => import('../../views/dratshang-asset/asset/assign-asset/checkin/index'))

//product
const ProductList = lazy(() => import('../../views/dratshang-asset/product/list'))
const AddProduct = lazy(() => import('../../views/dratshang-asset/product/add/index'))
const ViewProduct = lazy(() => import('../../views/dratshang-asset/product/view/index'))
const EditProduct = lazy(() => import('../../views/dratshang-asset/product/edit/index'))

//Accessory
const AccessoryList = lazy(() => import('../../views/dratshang-asset/accessory/list'))
const AddAccessory = lazy(() => import('../../views/dratshang-asset/accessory/add/index'))
const ViewAccessory = lazy(() => import('../../views/dratshang-asset/accessory/view/index'))
const EditAccessory = lazy(() => import('../../views/dratshang-asset/accessory/edit/index'))
const AssignForAccessoryCheckOut = lazy(() => import('../../views/dratshang-asset/assign/add/index'))
const AssignForAccessoryCheckIn = lazy(() => import('../../views/dratshang-asset/assign/checkin/index'))

const DratshangAssetRoutes = [
  {
    path: '/dratshang-asset/lists',
    element: <DratshangAssetList />,
    meta: {
      action: 'read',
      resource: 'Asset'
    }
  },
  {
    path: '/dratshang-asset/add',
    element: <AddDratshangAsset />,
    meta: {
      action: 'create',
      resource: 'Asset'
    }
  },
  {
    path: '/dratshang-asset/lists/view/:id',
    element: <ViewDratshangAsset />,
    meta: {
      action: 'read',
      resource: 'Asset'
    }
  },
  {
    path: '/dratshang-asset/lists/edit/:id',
    element: <EditDratshangAsset />,
    meta: {
      action: 'update',
      resource: 'Asset'
    }
  },

  {
    path: '/dratshang-asset/checkout/:id',
    element: <AssignForAssetCheckOut />,
    meta: {
      action: 'create',
      resource: 'Asset'
    }
  },
  {
    path: '/dratshang-asset/checkin/:id',
    element: <AssignForAssetCheckin />,
    meta: {
      action: 'create',
      resource: 'Asset'
    }
  },
  {
    path: '/product/lists',
    element: <ProductList />,
    meta: {
      action: 'read',
      resource: 'Asset'
    }
  },
  {
    path: '/product/add',
    element: <AddProduct />,
    meta: {
      action: 'create',
      resource: 'Asset'
    }
  },
  {
    path: '/product/lists/view/:id',
    element: <ViewProduct />,
    meta: {
      action: 'read',
      resource: 'Asset'
    }
  },
  {
    path: '/product/lists/edit/:id',
    element: <EditProduct />,
    meta: {
      action: 'update',
      resource: 'Asset'
    }
  },
  {
    path: '/accessory/lists',
    element: <AccessoryList />,
    meta: {
      action: 'read',
      resource: 'Asset'
    }
  },
  {
    path: '/accessory/add',
    element: <AddAccessory />,
    meta: {
      action: 'create',
      resource: 'Asset'
    }
  },
  {
    path: '/accessory/lists/view/:id',
    element: <ViewAccessory />,
    meta: {
      action: 'read',
      resource: 'Asset'
    }
  },
  {
    path: '/accessory/lists/edit/:id',
    element: <EditAccessory />,
    meta: {
      action: 'update',
      resource: 'Asset'
    }
  },
  {
    path: '/accessory/checkout/:id',
    element: <AssignForAccessoryCheckOut />,
    meta: {
      action: 'create',
      resource: 'Asset'
    }
  },
  {
    path: '/accessory/checkin/:id',
    element: <AssignForAccessoryCheckIn />,
    meta: {
      action: 'create',
      resource: 'Asset'
    }
  }
]

export default DratshangAssetRoutes
