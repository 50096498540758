import { lazy } from 'react'

const PermissionLists = lazy(() => import('../../views/permission/list/index'))
const AddPermission = lazy(() => import('../../views/permission/add/index'))
const EditPermission = lazy(() => import('../../views/permission/edit/index'))

const PermissionRoutes = [
  {
    path: '/permission/list',
    element: <PermissionLists />,
    meta: {
      action: 'manage',
      resource: 'Permissions'
    }
  },
  {
    path: '/permission/list/add',
    element: <AddPermission />,
    meta: {
      action: 'create',
      resource: 'Permissions'
    }
  },
  {
    path: '/permission/list/edit/:id',
    element: <EditPermission />,
    meta: {
      action: 'update',
      resource: 'Permissions'
    }
  }
]

export default PermissionRoutes
