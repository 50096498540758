import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//tien
const getAllTiens = async (params) => {
  const response = await api.get(`${servicePrefix}/tiens`, { params: stripEmptyObjects(params) })
  return response.data
}

const getTiensAll = async () => {
  const response = await api.get(`${servicePrefix}/tiens/all`)
  return response.data
}

const getTiensById = async (id) => {
  const response = await api.get(`${servicePrefix}/tiens/${id}`)
  return response.data
}

const addTiens = async (tien) => {
  console.log(tien)
  const response = await api.post(`${servicePrefix}/tiens`, tien)
  return response.data
}

const updateTiens = async (tien) => {
  const { id } = tien
  const response = await api.patch(`${servicePrefix}/tiens/${id}`, tien)
  return response.data
}

const deleteTiens = async (id) => {
  const response = await api.delete(`${servicePrefix}/tiens/${id}`)
  return response.data
}

const TienService = {
  getAllTiens,
  getTiensAll,
  getTiensById,
  addTiens,
  updateTiens,
  deleteTiens
}

export default TienService
