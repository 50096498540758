// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import WalletService from '../../../services/wallet'

export const getTransactions = createAsyncThunk('transaction/getTransactions', async (params, { rejectWithValue }) => {
  try {
    const response = await WalletService.getTransactions(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getTransactionById = createAsyncThunk('transaction/getTransactionById', async (id) => {
  const response = await WalletService.getTransactionById(id)
  return response
})

export const addTransaction = createAsyncThunk('transaction/addTransaction', async (transaction, { rejectWithValue }) => {
  console.log(transaction)
  try {
    const response = await WalletService.addTransaction(transaction)
    return response
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

export const updateTransaction = createAsyncThunk('transaction/updateTransaction', async (transaction, { rejectWithValue }) => {
  try {
    const response = await WalletService.updateTransaction(transaction)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteTransaction = createAsyncThunk('transaction/deleteTransaction', async (id, { dispatch, getState }) => {
  await WalletService.deleteTransaction(id)
  await dispatch(getTransactions(getState()?.transactions.params))
  return id
})

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualTransaction: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTransactionById.fulfilled, (state, action) => {
        state.individualTransaction = action.payload
        state.loading = false
      })
      .addCase(getTransactionById.pending, (state, action) => {
        state.individualTransaction = action.payload
        state.loading = true
      })
      .addCase(getTransactionById.rejected, (state, action) => {
        state.individualTransaction = action.payload
        state.loading = false
      })
  }
})

export default transactionSlice.reducer
