import { lazy } from 'react'

const AcademicYearList = lazy(() => import('../../views/academic-year/list/index'))
const AddAcademicYear = lazy(() => import('../../views/academic-year/add/index'))
const ViewAcademicYear = lazy(() => import('../../views/academic-year/view/index'))
const EditAcademicYear = lazy(() => import('../../views/academic-year/edit/index'))

const AcademicYearRoutes = [
  //kurim
  {
    path: '/academic-year/add',
    element: <AddAcademicYear />,
    meta: {
      action: 'create',
      resource: 'Grade'
    }
  },
  {
    path: '/academic-year/lists',
    element: <AcademicYearList />,
    meta: {
      action: 'read',
      resource: 'Grade'
    }
  },

  {
    path: '/academic-year/lists/edit/:id',
    element: <EditAcademicYear />,
    meta: {
      action: 'update',
      resource: 'Grade'
    }
  },
  {
    path: '/academic-year/lists/view/:id',
    element: <ViewAcademicYear />,
    meta: {
      action: 'read',
      resource: 'Grade'
    }
  }
]

export default AcademicYearRoutes
