// ** Redux Imports
import AcademicYearService from '@services/academic-year'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllAcademicYear = createAsyncThunk('academicYear/getAllAcademicYear', async (params) => {
  const response = await AcademicYearService.getAllAcademicYear(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAcademicYearById = createAsyncThunk('academicYear/getAcademicYearById', async (id) => {
  const response = await AcademicYearService.getAcademicYearById(id)
  return response
})

export const addAcademicYear = createAsyncThunk('academicYear/addAcademicYear', async (year, { rejectWithValue }) => {
  try {
    const response = await AcademicYearService.addAcademicYear(year)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateAcademicYear = createAsyncThunk('academicYear/updateAcademicYear', async (year, { rejectWithValue }) => {
  try {
    const response = await AcademicYearService.updateAcademicYear(year)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const AcademicYearSlice = createSlice({
  name: 'academic_year',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualAcademicYear: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAcademicYear.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAcademicYearById.fulfilled, (state, action) => {
        state.individualAcademicYear = action.payload
      })
  }
})

export default AcademicYearSlice.reducer
