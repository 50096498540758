import { ArrowRight, Book, BookOpen } from 'react-feather'

export default [
  {
    id: 'report',
    title: 'Report',
    icon: <Book size={20} />,
    children: [
      // {
      //   id: 'lhabang-report',
      //   title: 'Lhabang Report',
      //   icon: <BookOpen size={20} />,
      //   resource: 'Dashboard',
      //   children: [
      {
        id: 'member-report',
        title: 'Member',
        icon: <ArrowRight size={12} />,
        navLink: '/member-report',
        resource: 'LhabangReport'
      },
      {
        id: 'member-fee-report',
        title: 'Member Fee',
        icon: <ArrowRight size={12} />,
        navLink: '/member-fee-report',
        resource: 'LhabangReport'
      },
      {
        id: 'donation-report',
        title: 'Donation',
        icon: <ArrowRight size={12} />,
        navLink: '/donation-report',
        resource: 'LhabangReport'
      },
      //   ]
      // },
      // {
      //   id: 'monk-report',
      //   title: 'Monk Report',
      //   icon: <BookOpen size={20} />,
      //   resource: 'Dashboard',
      //   children: [
      {
        id: 'monk-report',
        title: 'Monk',
        icon: <ArrowRight size={12} />,
        navLink: '/monk-report',
        resource: 'MonkReport'
      },
      {
        id: 'tien-report',
        title: 'Tien',
        icon: <ArrowRight size={12} />,
        navLink: '/tien-report',
        resource: 'TienReport'
      },
      {
        id: 'mdesignation-report',
        title: 'MDesignation',
        icon: <ArrowRight size={12} />,
        navLink: '/mdesignation-report',
        resource: 'DesignationReport'
      },
      //   ]
      // },
      // {
      //   id: 'Administration-report',
      //   title: 'Administration Report',
      //   icon: <BookOpen size={20} />,
      //   resource: 'Dashboard',
      //   children: [
      {
        id: 'absent-report',
        title: 'Absent',
        icon: <ArrowRight size={12} />,
        navLink: '/absent-report',
        resource: 'AbsentReport'
      },
      {
        id: 'deputation-report',
        title: 'Deputation',
        icon: <ArrowRight size={12} />,
        navLink: '/deputation-report',
        resource: 'DeputationReport'
      },
      {
        id: 'leave-report',
        title: 'Leave',
        icon: <ArrowRight size={12} />,
        navLink: '/leave-report',
        resource: 'LeaveReport'
      },
      {
        id: 'tour-report',
        title: 'Tour',
        icon: <ArrowRight size={12} />,
        navLink: '/tour-report',
        resource: 'TourReport'
      },
      {
        id: 'kurim-report',
        title: 'Kurim',
        icon: <ArrowRight size={12} />,
        navLink: '/kurims',
        resource: 'PresentReport'
      },
      {
        id: 'present-report',
        title: 'Present',
        icon: <ArrowRight size={12} />,
        navLink: '/presents',
        resource: 'PresentReport'
      }
    ]
  }
  //   ]
  // }
]
