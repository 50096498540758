import { lazy } from 'react'

//membership
const MemberFeeList = lazy(() => import('../../views/collection-type/member-fee/list/index'))
const MemberFeeSearch = lazy(() => import('../../views/collection-type/member-fee/add/index'))
const AddMemberFee = lazy(() => import('../../views/collection-type/member-fee/add/AddMemberFee.js'))
const EditMemberFee = lazy(() => import('../../views/collection-type/member-fee/edit/index'))
const ViewMemberFee = lazy(() => import('../../views/collection-type/member-fee/view/index'))

//donation
const DonationList = lazy(() => import('../../views/collection-type/donation/list/index'))
const DonationIndex = lazy(() => import('../../views/collection-type/donation/add/index'))
const SearchDonationMember = lazy(() => import('../../views/collection-type/donation/add/Search'))
const MemberDonation = lazy(() => import('../../views/collection-type/donation/add/MemberDonation'))
const NonMemberDonation = lazy(() => import('../../views/collection-type/donation/add/NonMemberDonation'))
const OrganizationDonation = lazy(() => import('../../views/collection-type/donation/add/OrganizationDonation'))
const EditDonation = lazy(() => import('../../views/collection-type/donation/edit/index'))
const ViewDonation = lazy(() => import('../../views/collection-type/donation/view/index'))

const CollectionType = [
  //membership
  {
    path: '/collection/member-fee/lists',
    element: <MemberFeeList />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/collection/member-fee/member-search',
    element: <MemberFeeSearch />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },
  {
    path: '/collection/member-fee/member-search/add',
    element: <AddMemberFee />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/collection/member-fee/lists/edit/:id',
    element: <EditMemberFee />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/collection/member-fee/lists/view/:id',
    element: <ViewMemberFee />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  //donation
  {
    path: '/collection/donation/lists',
    element: <DonationList />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/collection/donation/donation-type',
    element: <DonationIndex />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/collection/donation/donation-type/member-search',
    element: <SearchDonationMember />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/collection/donation/donation-type/member',
    element: <MemberDonation />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },
  {
    path: '/collection/donation/donation-type/non-member',
    element: <NonMemberDonation />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },
  {
    path: '/collection/donation/donation-type/organization',
    element: <OrganizationDonation />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/collection/donation/lists/edit/:id',
    element: <EditDonation />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/collection/donation/lists/view/:id',
    element: <ViewDonation />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  }
]

export default CollectionType
