import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//deputation
const getAllDeputation = async (params) => {
  const response = await api.get(`${servicePrefix}/deputations`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDeputationById = async (id) => {
  const response = await api.get(`${servicePrefix}/deputations/${id}`)
  return response.data
}

const addDeputation = async (deputation) => {
  // console.log(deputation)
  const response = await api.post(`${servicePrefix}/deputations`, deputation)
  return response.data
}

const updateDeputation = async (deputation) => {
  const { id } = deputation
  const response = await api.patch(`${servicePrefix}/deputations/${id}`, deputation)
  return response.data
}

const deleteDeputation = async (id) => {
  const response = await api.delete(`${servicePrefix}/deputations/${id}`)
  return response.data
}

//tour
const getAllTour = async (params) => {
  const response = await api.get(`${servicePrefix}/tours`, { params: stripEmptyObjects(params) })
  return response.data
}

const getTourById = async (id) => {
  const response = await api.get(`${servicePrefix}/tours/${id}`)
  return response.data
}

const addTour = async (tour) => {
  // console.log(tour)
  const response = await api.post(`${servicePrefix}/tours`, tour)
  return response.data
}

const updateTour = async (tour) => {
  const { id } = tour
  const response = await api.patch(`${servicePrefix}/tours/${id}`, tour)
  return response.data
}

const deleteTour = async (id) => {
  const response = await api.delete(`${servicePrefix}/tours/${id}`)
  return response.data
}

//daily attendance
const getAllDailyAttendance = async (params) => {
  const response = await api.get(`${servicePrefix}/absents`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDailyAttendanceById = async (id) => {
  const response = await api.get(`${servicePrefix}/absents/${id}`)
  return response.data
}

const addDailyAttendance = async (absent) => {
  // console.log(absent)
  const response = await api.post(`${servicePrefix}/absents`, absent)
  return response.data
}

const updateDailyAttendance = async (absent) => {
  const { id } = absent
  const response = await api.patch(`${servicePrefix}/absents/${id}`, absent)
  return response.data
}

const deleteDailyAttendance = async (id) => {
  const response = await api.delete(`${servicePrefix}/absents/${id}`)
  return response.data
}

//leave
const getAllLeave = async (params) => {
  const response = await api.get(`${servicePrefix}/leaves`, { params: stripEmptyObjects(params) })
  return response.data
}

const getLeaveById = async (id) => {
  const response = await api.get(`${servicePrefix}/leaves/${id}`)
  return response.data
}

const approveLeave = async (id) => {
  const response = await api.post(`${servicePrefix}/leaves/${id}/approve-application`)
  return response.data
}

const addLeave = async (leave) => {
  // console.log(leave)
  const response = await api.post(`${servicePrefix}/leaves`, leave)
  return response.data
}

const updateLeave = async (leave) => {
  const { id } = leave
  const response = await api.patch(`${servicePrefix}/leaves/${id}`, leave)
  return response.data
}

const deleteLeave = async (id) => {
  const response = await api.delete(`${servicePrefix}/leaves/${id}`)
  return response.data
}

const AttendenceService = {
  //deputation
  getAllDeputation,
  getDeputationById,
  addDeputation,
  updateDeputation,
  deleteDeputation,

  //tour
  getAllTour,
  getTourById,
  addTour,
  updateTour,
  deleteTour,

  //daily attendance
  getAllDailyAttendance,
  getDailyAttendanceById,
  addDailyAttendance,
  updateDailyAttendance,
  deleteDailyAttendance,

  //leave
  getAllLeave,
  getLeaveById,
  addLeave,
  updateLeave,
  approveLeave,
  deleteLeave
}

export default AttendenceService
