// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DratshangAssetService from '@services/dratshang-asset'

export const getAllDratshangAsset = createAsyncThunk('dratshangAsset/getAllDratshangAsset', async (params, { rejectWithValue }) => {
  try {
    const response = await DratshangAssetService.getAllDratshangAsset(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDratshangAssetById = createAsyncThunk('dratshangAsset/getDratshangAssetById', async (id) => {
  const response = await DratshangAssetService.getDratshangAssetById(id)
  // console.log('dratshang store', response)
  return response
})

export const addDratshangAsset = createAsyncThunk('dratshangAsset/addDratshangAsset', async (dratshangAsset, { rejectWithValue }) => {
  try {
    const response = await DratshangAssetService.addDratshangAsset(dratshangAsset)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDratshangAsset = createAsyncThunk('dratshangAsset/updateDratshangAsset', async (dratshangAsset, { rejectWithValue }) => {
  try {
    const response = await DratshangAssetService.updateDratshangAsset(dratshangAsset)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteDratshangAsset = createAsyncThunk('dratshangAsset/deleteDratshangAsset', async (id, { dispatch, getState }) => {
  await DratshangAssetService.deleteDratshangAsset(id)
  await dispatch(getAllDratshangAsset(getState()?.dratshangAssets.params))
  return id
})

export const dratshangAssetSlice = createSlice({
  name: 'dratshang_asset',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDratshangAsset: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDratshangAsset.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDratshangAssetById.fulfilled, (state, action) => {
        state.individualDratshangAsset = action.payload
        state.loading = false
      })
      .addCase(getDratshangAssetById.pending, (state, action) => {
        state.individualDratshangAsset = action.payload
        state.loading = true
      })
      .addCase(getDratshangAssetById.rejected, (state, action) => {
        state.individualDratshangAsset = action.payload
        state.loading = false
      })
  }
})

export default dratshangAssetSlice.reducer
