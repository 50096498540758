import { lazy } from 'react'

const KurimList = lazy(() => import('../../views/kurim/list/index'))
const AddKurim = lazy(() => import('../../views/kurim/add/index'))
const ViewKurim = lazy(() => import('../../views/kurim/view/index'))
const EditKurim = lazy(() => import('../../views/kurim/edit/index'))
const AddMoreMonk = lazy(() => import('../../views/kurim/add-more-monk/index'))

const KurimRoutes = [
  //kurim
  {
    path: '/kurim/add',
    element: <AddKurim />,
    meta: {
      action: 'create',
      resource: 'Kurim'
    }
  },
  {
    path: '/kurim/lists',
    element: <KurimList />,
    meta: {
      action: 'read',
      resource: 'Kurim'
    }
  },
  {
    path: '/kurim/lists/view/:id',
    element: <ViewKurim />,
    meta: {
      action: 'read',
      resource: 'Kurim'
    }
  },
  {
    path: '/kurim/lists/edit/:id',
    element: <EditKurim />,
    meta: {
      action: 'update',
      resource: 'Kurim'
    }
  },
  {
    path: '/kurim/lists/add-more-monk/:id',
    element: <AddMoreMonk />,
    meta: {
      action: 'create',
      resource: 'Kurim'
    }
  }
]

export default KurimRoutes
