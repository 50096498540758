// ** Redux Imports
import AttendenceService from '@services/attendance'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllLeave = createAsyncThunk('leave/getAllLeave', async (params, { rejectWithValue }) => {
  try {
    const response = await AttendenceService.getAllLeave(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getLeaveById = createAsyncThunk('leave/getLeaveById', async (id) => {
  const response = await AttendenceService.getLeaveById(id)
  return response
})

export const addLeave = createAsyncThunk('leave/addLeave', async (leave, { rejectWithValue }) => {
  try {
    const response = await AttendenceService.addLeave(leave)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLeave = createAsyncThunk('leave/updateLeave', async (leave) => {
  const response = await AttendenceService.updateLeave(leave)
  return response
})

export const deleteLeave = createAsyncThunk('leave/deleteLeave', async (id, { dispatch, getState }) => {
  await AttendenceService.deleteLeave(id)
  await dispatch(getAllLeave(getState()?.leaves.params))
  return id
})

export const LeaveSlice = createSlice({
  name: 'leave',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLeave: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeave.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLeaveById.fulfilled, (state, action) => {
        state.individualLeave = action.payload
        state.loading = false
      })
      .addCase(getLeaveById.pending, (state, action) => {
        state.individualLeave = action.payload
        state.loading = true
      })
      .addCase(getLeaveById.rejected, (state, action) => {
        state.individualLeave = action.payload
        state.loading = false
      })
  }
})

export default LeaveSlice.reducer
