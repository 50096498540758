import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//get  kurim
const getPermissions = async (params) => {
  const response = await api.get(`${servicePrefix}/permissions`, { params: stripEmptyObjects(params) })
  return response.data
}

//get one kurim
const getPermissionIndividual = async (id) => {
  const response = await api.get(`${servicePrefix}/permissions/${id}`)
  return response.data
}

const getAllPermissions = async () => {
  const response = await api.get(`${servicePrefix}/permissions/all`)
  return response.data
}

//add kurim
const addPermission = async (permissions) => {
  try {
    const response = await api.post(`${servicePrefix}/permissions`, permissions)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

//TODO refractor to work with patch after server api changes
const updatePermissionIndividual = async (permissions) => {
  const { id } = permissions
  const response = await api.patch(`${servicePrefix}/permissions/${id}`, stripEmptyObjects(permissions))
  return response.data
}

const PermissionService = {
  getPermissions,
  getPermissionIndividual,
  addPermission,
  updatePermissionIndividual,
  getAllPermissions
}

export default PermissionService
