// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/masters'

export const getDzongkhag = createAsyncThunk('dzongkhag/getDzongkhag', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllDzongkhag(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDzongkhagById = createAsyncThunk('dzongkhag/getDzongkhagById', async (id) => {
  const response = await MasterService.getDzongkhagById(id)
  return response
})

export const addDzongkhag = createAsyncThunk('dzongkhag/addDzongkhag', async (dzongkhag, { rejectWithValue }) => {
  try {
    const response = await MasterService.addDzongkhag(dzongkhag)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDzongkhag = createAsyncThunk('dzongkhag/updateDzongkhag', async (dzongkhag, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateDzongkhag(dzongkhag)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteDzongkhag = createAsyncThunk('dzongkhag/deleteDzongkhag', async (id, { dispatch, getState }) => {
  await MasterService.deleteDzongkhag(id)
  await dispatch(getDzongkhag(getState()?.dzongkhags.params))
  return id
})

export const dzongkhagSlice = createSlice({
  name: 'dzongkhag',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDzongkhag: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDzongkhag.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDzongkhagById.fulfilled, (state, action) => {
        state.individualDzongkhag = action.payload
        state.loading = false
      })
      .addCase(getDzongkhagById.pending, (state, action) => {
        state.individualDzongkhag = action.payload
        state.loading = true
      })
      .addCase(getDzongkhagById.rejected, (state, action) => {
        state.individualDzongkhag = action.payload
        state.loading = false
      })
  }
})

export default dzongkhagSlice.reducer
