// ** Third Party Components
import { useTranslation } from 'react-i18next'
import ReactCountryFlag from 'react-country-flag'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'

const IntlDropdown = () => {
  // ** Hooks
  const { i18n } = useTranslation()

  // ** Vars
  const langObj = {
    en: 'English',
    bt: 'Bhutan'
    // fr: 'French',
    // pt: 'Portuguese'
  }

  // ** Function to switch Language
  const handleLangUpdate = (e, lang) => {
    e.preventDefault()
    i18n.changeLanguage(lang)
  }

  return (
    <UncontrolledDropdown href="/" tag="li" className="dropdown-language nav-item">
      <DropdownToggle href="/" tag="a" className="nav-link" onClick={(e) => e.preventDefault()}>
        <ReactCountryFlag svg className="country-flag flag-icon" countryCode={i18n.language === 'en' ? 'us' : i18n.language} />
        <span className="selected-language">{langObj[i18n.language]}</span>
      </DropdownToggle>
      <DropdownMenu className="mt-0" end>
        <DropdownItem href="/" tag="a" onClick={(e) => handleLangUpdate(e, 'en')}>
          <ReactCountryFlag className="country-flag" countryCode="us" svg />
          <span className="ms-1">English</span>
        </DropdownItem>
        <DropdownItem href="/" tag="a" onClick={(e) => handleLangUpdate(e, 'bt')}>
          <ReactCountryFlag className="country-flag" countryCode="bt" svg />
          <span className="ms-1">Bhutan</span>
        </DropdownItem>
        {/* <DropdownItem href="/" tag="a" onClick={(e) => handleLangUpdate(e, 'de')}>
          <ReactCountryFlag className="country-flag" countryCode="de" svg />
          <span className="ms-1">German</span>
        </DropdownItem>
        <DropdownItem href="/" tag="a" onClick={(e) => handleLangUpdate(e, 'pt')}>
          <ReactCountryFlag className="country-flag" countryCode="pt" svg />
          <span className="ms-1">Portuguese</span>
        </DropdownItem> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default IntlDropdown
