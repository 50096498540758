import { lazy } from 'react'

//country
const CountryList = lazy(() => import('../../views/master/country/list/index'))
const AddCountry = lazy(() => import('../../views/master/country/add/index'))
const EditCountry = lazy(() => import('../../views/master/country/edit/index'))

//dzongkhag
const DzongkhagList = lazy(() => import('../../views/master/dzongkhag/list/index'))
const AddDzongkhag = lazy(() => import('../../views/master/dzongkhag/add/index'))
const EditDzongkhag = lazy(() => import('../../views/master/dzongkhag/edit/index'))

//gewog
const GewogList = lazy(() => import('../../views/master/gewog/list/index'))
const AddGewog = lazy(() => import('../../views/master/gewog/add/index'))
const EditGewog = lazy(() => import('../../views/master/gewog/edit/index'))

//fee-type
const FeeTypeList = lazy(() => import('../../views/master/fee-type/list/index'))
const AddFeeType = lazy(() => import('../../views/master/fee-type/add/index'))
const EditFeeType = lazy(() => import('../../views/master/fee-type/edit/index'))

//bank
const BankList = lazy(() => import('../../views/master/bank/list/index'))
const AddBank = lazy(() => import('../../views/master/bank/add/index'))
const EditBank = lazy(() => import('../../views/master/bank/edit/index'))

//dratshang asset type
const AssetTypeList = lazy(() => import('../../views/master/asset-type/list/index'))
const AddAssetType = lazy(() => import('../../views/master/asset-type/add/index'))
const EditAssetType = lazy(() => import('../../views/master/asset-type/edit/index'))

//dratshang category
const DratshangLevelList = lazy(() => import('../../views/master/dratshang-level/list/index'))
const AddDratshangLevel = lazy(() => import('../../views/master/dratshang-level/add/index'))
const EditDratshangLevel = lazy(() => import('../../views/master/dratshang-level/edit/index'))

//designation
const DesignationList = lazy(() => import('../../views/master/designation/list/index'))
const AddDesignation = lazy(() => import('../../views/master/designation/add/index'))
const EditDesignation = lazy(() => import('../../views/master/designation/edit/index'))

//grade-category
const GradeCategoryList = lazy(() => import('../../views/master/grade-category/list/index'))
const AddGradeCategory = lazy(() => import('../../views/master/grade-category/add/index'))
const EditGradeCategory = lazy(() => import('../../views/master/grade-category/edit/index'))

//grade
const GradeList = lazy(() => import('../../views/master/grade/list/index'))
const AddGrade = lazy(() => import('../../views/master/grade/add/index'))
const EditGrade = lazy(() => import('../../views/master/grade/edit/index'))

//checklist category
const CheckListCategory = lazy(() => import('../../views/master/checklist-category/list/index'))
const AddChecklistCategory = lazy(() => import('../../views/master/checklist-category/add/index'))
const EditCheckListCategory = lazy(() => import('../../views/master/checklist-category/edit/index'))

//checklist item
const CheckListItem = lazy(() => import('../../views/master/checklist-item/list/index'))
const AddCheckListItem = lazy(() => import('../../views/master/checklist-item/add/index'))
const EditCheckListItem = lazy(() => import('../../views/master/checklist-item/edit/index'))

//donation type
const DonationType = lazy(() => import('../../views/master/donation-type/list/index'))
const AddDonationType = lazy(() => import('../../views/master/donation-type/add/index'))
const EditDonationType = lazy(() => import('../../views/master/donation-type/edit/index'))

const MasterRoutes = [
  //country
  {
    path: '/country',
    element: <CountryList />,
    meta: {
      action: 'manage',
      resource: 'Country'
    }
  },
  {
    path: '/country/add',
    element: <AddCountry />,
    meta: {
      action: 'manage',
      resource: 'Country'
    }
  },
  {
    path: '/country/edit/:id',
    element: <EditCountry />,
    meta: {
      action: 'manage',
      resource: 'Country'
    }
  },

  //dzongkhag
  {
    path: '/dzongkhag',
    element: <DzongkhagList />,
    meta: {
      action: 'manage',
      resource: 'Dzongkhag'
    }
  },
  {
    path: '/dzongkhag/add',
    element: <AddDzongkhag />,
    meta: {
      action: 'manage',
      resource: 'Dzongkhag'
    }
  },
  {
    path: '/dzongkhag/edit/:id',
    element: <EditDzongkhag />,
    meta: {
      action: 'manage',
      resource: 'Dzongkhag'
    }
  },

  //gewog
  {
    path: '/gewog',
    element: <GewogList />,
    meta: {
      action: 'manage',
      resource: 'Gewog'
    }
  },
  {
    path: '/gewog/add',
    element: <AddGewog />,
    meta: {
      action: 'manage',
      resource: 'Gewog'
    }
  },
  {
    path: '/gewog/edit/:id',
    element: <EditGewog />,
    meta: {
      action: 'manage',
      resource: 'Gewog'
    }
  },

  //fee-type
  {
    path: '/fee-type',
    element: <FeeTypeList />,
    meta: {
      action: 'manage',
      resource: 'FeeType'
    }
  },
  {
    path: '/fee-type/add',
    element: <AddFeeType />,
    meta: {
      action: 'manage',
      resource: 'FeeType'
    }
  },
  {
    path: '/fee-type/edit/:id',
    element: <EditFeeType />,
    meta: {
      action: 'manage',
      resource: 'FeeType'
    }
  },

  //bank
  {
    path: '/bank',
    element: <BankList />,
    meta: {
      action: 'manage',
      resource: 'Bank'
    }
  },
  {
    path: '/bank/add',
    element: <AddBank />,
    meta: {
      action: 'manage',
      resource: 'Bank'
    }
  },
  {
    path: '/bank/edit/:id',
    element: <EditBank />,
    meta: {
      action: 'manage',
      resource: 'Bank'
    }
  },

  //dratshang asset type
  {
    path: '/asset-type',
    element: <AssetTypeList />,
    meta: {
      action: 'manage',
      resource: 'AssetType'
    }
  },
  {
    path: '/asset-type/add',
    element: <AddAssetType />,
    meta: {
      action: 'manage',
      resource: 'AssetType'
    }
  },
  {
    path: '/asset-type/edit/:id',
    element: <EditAssetType />,
    meta: {
      action: 'manage',
      resource: 'AssetType'
    }
  },

  //dratshang category
  {
    path: '/dratshang-level',
    element: <DratshangLevelList />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/dratshang-level/add',
    element: <AddDratshangLevel />,
    meta: {
      action: 'create',
      resource: 'Masters'
    }
  },
  {
    path: '/dratshang-level/edit/:id',
    element: <EditDratshangLevel />,
    meta: {
      action: 'update',
      resource: 'Masters'
    }
  },

  //designation
  {
    path: '/designation',
    element: <DesignationList />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/designation/add',
    element: <AddDesignation />,
    meta: {
      action: 'create',
      resource: 'Masters'
    }
  },
  {
    path: '/designation/edit/:id',
    element: <EditDesignation />,
    meta: {
      action: 'update',
      resource: 'Masters'
    }
  },

  //grade-category
  {
    path: '/grade-category',
    element: <GradeCategoryList />,
    meta: {
      action: 'manage',
      resource: '/grade-category'
    }
  },
  {
    path: '/grade-category/add',
    element: <AddGradeCategory />,
    meta: {
      action: 'manage',
      resource: '/grade-category'
    }
  },
  {
    path: '/grade-category/edit/:id',
    element: <EditGradeCategory />,
    meta: {
      action: 'manage',
      resource: '/grade-category'
    }
  },

  //grade
  {
    path: '/grade',
    element: <GradeList />,
    meta: {
      action: 'manage',
      resource: 'Shedra'
    }
  },
  {
    path: '/grade/add',
    element: <AddGrade />,
    meta: {
      action: 'manage',
      resource: 'Shedra'
    }
  },
  {
    path: '/grade/edit/:id',
    element: <EditGrade />,
    meta: {
      action: 'manage',
      resource: 'Shedra'
    }
  },

  //dratshang category
  {
    path: '/dratshang-level',
    element: <DratshangLevelList />,
    meta: {
      action: 'manage',
      resource: 'DratshangLevel'
    }
  },
  {
    path: '/dratshang-level/add',
    element: <AddDratshangLevel />,
    meta: {
      action: 'manage',
      resource: 'DratshangLevel'
    }
  },
  {
    path: '/dratshang-level/edit/:id',
    element: <EditDratshangLevel />,
    meta: {
      action: 'manage',
      resource: 'DratshangLevel'
    }
  },

  //designation
  {
    path: '/designation',
    element: <DesignationList />,
    meta: {
      action: 'manage',
      resource: 'Designation'
    }
  },
  {
    path: '/designation/add',
    element: <AddDesignation />,
    meta: {
      action: 'manage',
      resource: 'Designation'
    }
  },
  {
    path: '/designation/edit/:id',
    element: <EditDesignation />,
    meta: {
      action: 'manage',
      resource: 'Designation'
    }
  },

  //grade
  {
    path: '/grade',
    element: <GradeList />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/grade/add',
    element: <AddGrade />,
    meta: {
      action: 'create',
      resource: 'Masters'
    }
  },
  {
    path: '/grade/edit/:id',
    element: <EditGrade />,
    meta: {
      action: 'update',
      resource: 'Masters'
    }
  },

  //checklist category
  {
    path: '/checklist-category',
    element: <CheckListCategory />,
    meta: {
      action: 'manage',
      resource: 'ChecklistCategory'
    }
  },
  {
    path: '/checklist-category/add',
    element: <AddChecklistCategory />,
    meta: {
      action: 'manage',
      resource: 'ChecklistCategory'
    }
  },
  {
    path: '/checklist-category/edit/:id',
    element: <EditCheckListCategory />,
    meta: {
      action: 'manage',
      resource: 'ChecklistCategory'
    }
  },

  //checklist item
  {
    path: '/checklist-item',
    element: <CheckListItem />,
    meta: {
      action: 'manage',
      resource: 'ChecklistItem'
    }
  },
  {
    path: '/checklist-item/add',
    element: <AddCheckListItem />,
    meta: {
      action: 'manage',
      resource: 'ChecklistItem'
    }
  },
  {
    path: '/checklist-item/edit/:id',
    element: <EditCheckListItem />,
    meta: {
      action: 'manage',
      resource: 'ChecklistItem'
    }
  },

  //donation type
  {
    path: '/donation-type',
    element: <DonationType />,
    meta: {
      action: 'manage',
      resource: 'DonationType'
    }
  },
  {
    path: '/donation-type/add',
    element: <AddDonationType />,
    meta: {
      action: 'manage',
      resource: 'DonationType'
    }
  },
  {
    path: '/donation-type/edit/:id',
    element: <EditDonationType />,
    meta: {
      action: 'manage',
      resource: 'DonationType'
    }
  }
]

export default MasterRoutes
