import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

const getKurimContribution = async (params) => {
  const response = await api.get(`${servicePrefix}/kurim-contributions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getKurimContributionById = async (id) => {
  const response = await api.get(`${servicePrefix}/kurim-contributions/${id}`)
  return response.data
}

const addKurimContribution = async (contribution) => {
  try {
    const response = await api.post(`${servicePrefix}/kurim-contributions`, contribution)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const updateKurimContribution = async (contribution) => {
  const { id } = contribution
  console.log('hhhh', id)
  const response = await api.patch(`${servicePrefix}/kurim-contributions/${id}`, contribution)
  console.log('fff', response)
  return response.data
}

const deleteKurimContribution = async (id) => {
  const response = await api.delete(`${servicePrefix}/kurim-contributions/${id}`)
  return response.data
}

const KurimContributionService = {
  getKurimContribution,
  getKurimContributionById,
  addKurimContribution,
  updateKurimContribution,
  deleteKurimContribution
}

export default KurimContributionService
