// ** Core JWT Import
import useJwt from '@src/@core/auth/jwt/useJwt'
const { jwt } = useJwt({
  loginEndpoint: `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
  registerEndpoint: `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
  refreshEndpoint: `${process.env.REACT_APP_API_BASE_URL}auth/refresh`,
  logoutEndpoint: `${process.env.REACT_APP_API_BASE_URL}/auth/logout`,

  // loginEndpoint: '/jwt/login',
  // registerEndpoint: '/jwt/register',
  // refreshEndpoint: '/jwt/refresh-token',
  // logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
})

export default jwt
