// ** Redux Imports
import KurimService from '@services/kurim'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getKurim = createAsyncThunk('kurim/getKurim', async (params) => {
  const response = await KurimService.getKurims(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getKurimById = createAsyncThunk('kurim/getKurimById', async (id) => {
  const response = await KurimService.getKurimById(id)
  return response
})

export const addKurim = createAsyncThunk('kurim/addKurim', async (kurim, { rejectWithValue }) => {
  try {
    const response = await KurimService.addKurim(kurim)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateKurim = createAsyncThunk('kurim/updateKurim', async (kurim, { rejectWithValue }) => {
  try {
    const response = await KurimService.updateKurim(kurim)
    console.log('res', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteKurim = createAsyncThunk('kurim/deleteKurim', async (id, { dispatch, getState }) => {
  await KurimService.deleteKurim(id)
  await dispatch(getKurim(getState()?.kurims.params))
  return id
})

export const deleteMonkInKurim = createAsyncThunk('kurim/deleteMonkInKurim', async (data) => {
  await KurimService.deleteMonksInKurim({ data })
})

export const KurimsSlice = createSlice({
  name: 'kurims',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualKurim: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKurim.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getKurimById.fulfilled, (state, action) => {
        state.individualKurim = action.payload
        state.loading = false
      })
      .addCase(getKurimById.pending, (state, action) => {
        state.individualKurim = action.payload
        state.loading = true
      })
      .addCase(getKurimById.rejected, (state, action) => {
        state.individualKurim = action.payload
        state.loading = false
      })
  }
})

export default KurimsSlice.reducer
