import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//employee
const getAllStaff = async (params) => {
  const response = await api.get(`${servicePrefix}/staffs`, { params: stripEmptyObjects(params) })
  return response.data
}

const getStaffById = async (id) => {
  const response = await api.get(`${servicePrefix}/staffs/${id}`)
  return response.data
}

const addStaff = async (staff) => {
  const response = await api.post(`${servicePrefix}/staffs`, staff)
  return response.data
}

const updateStaff = async (staff) => {
  const { id } = staff
  const response = await api.patch(`${servicePrefix}/staffs/${id}`, staff)
  return response.data
}

const deleteStaff = async (id) => {
  const response = await api.delete(`${servicePrefix}/staffs/${id}`)
  return response.data
}

const searchStaff = async (search) => {
  const response = await api.get(`${servicePrefix}/staffs/search-by-identityno-mobileno/${encodeURIComponent(search)}`)
  return response.data
}

const getTotalStaff = async () => {
  const response = await api.get(`${servicePrefix}/staffs/get-total-staff`)
  return response.data
}

const EmployeeService = {
  //membership
  getAllStaff,
  getStaffById,
  addStaff,
  updateStaff,
  deleteStaff,
  searchStaff,
  getTotalStaff
}

export default EmployeeService
