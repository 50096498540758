// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/masters'

export const getAllChecklistCategory = createAsyncThunk('checklist/getAllChecklistCategory', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllChecklistCategory(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getChecklistCategoryById = createAsyncThunk('checklist/getChecklistCategoryById', async (id) => {
  const response = await MasterService.getChecklistCategoryById(id)
  return response
})

export const addChecklistCategory = createAsyncThunk('checklist/addChecklistCategory', async (checklist, { rejectWithValue }) => {
  try {
    const response = await MasterService.addChecklistCategory(checklist)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateChecklistCategory = createAsyncThunk('checklist/updateChecklistCategory', async (checklist, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateChecklistCategory(checklist)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteChecklistCategory = createAsyncThunk('checklist/deleteChecklistCategory', async (id, { dispatch, getState }) => {
  await MasterService.deleteChecklistCategory(id)
  await dispatch(getAllChecklistCategory(getState()?.checklistCategories.params))
  return id
})

export const checklistCategorySlice = createSlice({
  name: 'checklist_category',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualChecklistCategory: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllChecklistCategory.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getChecklistCategoryById.fulfilled, (state, action) => {
        state.individualChecklistCategory = action.payload
        state.loading = false
      })
      .addCase(getChecklistCategoryById.pending, (state, action) => {
        state.individualChecklistCategory = action.payload
        state.loading = true
      })
      .addCase(getChecklistCategoryById.rejected, (state, action) => {
        state.individualChecklistCategory = action.payload
        state.loading = false
      })
  }
})

export default checklistCategorySlice.reducer
