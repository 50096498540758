import { lazy } from 'react'

const HomePortal = lazy(() => import('../../views/public-portal/Home'))
const MemberDetail = lazy(() => import('../../views/public-portal/member-detail/MemberDetail'))
const MonkDetail = lazy(() => import('../../views/public-portal/monk-detail/MonkDetail'))
const Account = lazy(() => import('../../views/public-portal/account/index'))
const TaxExemptionCertificateIndexForMembers = lazy(() => import('../../views/public-portal/tax-exemption-certificate/members/index'))
const GenerateCertificateForMembers = lazy(() => import('../../views/public-portal/tax-exemption-certificate/members/taxExemptionCertificateGenerate'))
const TaxExemptionCertificateIndexForDonations = lazy(() => import('../../views/public-portal/tax-exemption-certificate/donations/index'))
const GenerateCertificateForDonations = lazy(() => import('../../views/public-portal/tax-exemption-certificate/donations/taxExemptionCertificateGenerate'))

const PublicPortalRoutes = [
  {
    path: '/home',
    element: <HomePortal />,
    meta: {
      layout: 'blank',
      publicRoute: true
      //   restricted: true
    }
  },
  {
    path: '/member-details',
    element: <MemberDetail />,
    meta: {
      layout: 'blank',
      publicRoute: true
      //   restricted: true
    }
  },
  {
    path: '/monk-details',
    element: <MonkDetail />,
    meta: {
      layout: 'blank',
      publicRoute: true
      //   restricted: true
    }
  },
  {
    path: '/member-search',
    element: <TaxExemptionCertificateIndexForMembers />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/generate-certificate-for-members',
    element: <GenerateCertificateForMembers />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/donation-search',
    element: <TaxExemptionCertificateIndexForDonations />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/generate-certificate-for-donations',
    element: <GenerateCertificateForDonations />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/accounts',
    element: <Account />,
    meta: {
      layout: 'blank',
      publicRoute: true
      //   restricted: true
    }
  }
]

export default PublicPortalRoutes
