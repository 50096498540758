// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/masters'

export const getAllGradeCategory = createAsyncThunk('gradeCategory/getAllGradeCategory', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllGradeCategory(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getGradeCategoryById = createAsyncThunk('gradeCategory/getGradeCategoryById', async (id) => {
  const response = await MasterService.getGradeCategoryById(id)
  return response
})

export const addGradeCategory = createAsyncThunk('gradeCategory/addGradeCategory', async (gradeCategory, { rejectWithValue }) => {
  try {
    const response = await MasterService.addGradeCategory(gradeCategory)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateGradeCategory = createAsyncThunk('gradeCategory/updateGradeCategory', async (gradeCategory, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateGradeCategory(gradeCategory)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteGradeCategory = createAsyncThunk('gradeCategory/deleteGradeCategory', async (id, { dispatch, getState }) => {
  await MasterService.deleteGradeCategory(id)
  await dispatch(getAllGradeCategory(getState()?.gradeCategories.params))
  return id
})

export const gradeCategorySlice = createSlice({
  name: 'grade_category',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualGradeCategory: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllGradeCategory.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getGradeCategoryById.fulfilled, (state, action) => {
        state.individualGradeCategory = action.payload
        state.loading = false
      })
      .addCase(getGradeCategoryById.pending, (state, action) => {
        state.individualGradeCategory = action.payload
        state.loading = true
      })
      .addCase(getGradeCategoryById.rejected, (state, action) => {
        state.individualGradeCategory = action.payload
        state.loading = false
      })
  }
})

export default gradeCategorySlice.reducer
