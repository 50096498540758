// ** React Imports
import { Fragment } from 'react'

// ** Routes Imports
import AppRoutes from './Apps'
import FormRoutes from './Forms'
import PagesRoutes from './Pages'
import TablesRoutes from './Tables'
import ChartsRoutes from './Charts'
import DashboardRoutes from './Dashboards'
import UiElementRoutes from './UiElements'
import ExtensionsRoutes from './Extensions'
import PageLayoutsRoutes from './PageLayouts'
import AuthenticationRoutes from './Authentication'
import MonkInformationRoutes from './MonkInformation'
import MonkDesignationRoutes from './MonkDesignation'
import TienRoutes from './Tien'
import MonkAttendanceRoutes from './MonkAttendance'
import KurimRoutes from './Kurim'
import AcademicYearRoutes from './AcademicYear'
import MonkGradeRoutes from './MonkGrade'

import MonkExpenseRoutes from './MonkExpense'
import DratshangRoutes from './Dratshang'
import DratshangAssetRoutes from './DratshangAsset'
import MasterRoutes from './Masters'

import MembershipRoutes from './Membership'
import CollectionTypeRoute from './CollectionType'

// ** Layouts
import BlankLayout from '@layouts/BlankLayout'
import VerticalLayout from '@src/layouts/VerticalLayout'
import HorizontalLayout from '@src/layouts/HorizontalLayout'
import LayoutWrapper from '@src/@core/layouts/components/layout-wrapper'

// ** Route Components
import PublicRoute from '@components/routes/PublicRoute'
import PrivateRoute from '@components/routes/PrivateRoute'

// ** Utils
import { isObjEmpty } from '@utils'
import PermissionRoutes from './Permission'
import RoleRoutes from './Role'
import UserRoutes from './User'
import WalletRoutes from './Wallet'
import TransactionRoutes from './Transaction'
import KurimContributionRoutes from './KurimContribution'
import PublicPortalRoutes from './PublicPortal'
import reportRoutes from './Report'
import StaffRoutes from './Staff'

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />
}

// ** Document title
const TemplateTitle = '%s - Dratshang'

// ** Default Route
const DefaultRoute = '/dashboard'

// ** Merge Routes
const Routes = [...AuthenticationRoutes, ...DashboardRoutes, ...AppRoutes, ...PagesRoutes, ...UiElementRoutes, ...ExtensionsRoutes, ...PageLayoutsRoutes, ...FormRoutes, ...TablesRoutes, ...ChartsRoutes, ...MonkInformationRoutes, ...MonkDesignationRoutes, ...MonkAttendanceRoutes, ...MonkGradeRoutes, ...AcademicYearRoutes, ...KurimRoutes, ...KurimContributionRoutes, ...TienRoutes, ...MembershipRoutes, ...CollectionTypeRoute, ...MonkExpenseRoutes, ...DratshangRoutes, ...DratshangAssetRoutes, ...MasterRoutes, ...PermissionRoutes, ...RoleRoutes, ...UserRoutes, ...WalletRoutes, ...TransactionRoutes, ...PublicPortalRoutes, ...reportRoutes, ...StaffRoutes]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if ((route.meta && route.meta.layout && route.meta.layout === layout) || ((route.meta === undefined || route.meta.layout === undefined) && defaultLayout === layout)) {
        let RouteTag = PrivateRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === 'blank' ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.publicRoute ? PublicRoute : PrivateRoute
        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || 'vertical'
  const layouts = ['vertical', 'horizontal', 'blank']

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)

    AllRoutes.push({
      path: '/',
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
