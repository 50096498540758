import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//member-fee
const getAllMemberFee = async (params) => {
  const response = await api.get(`${servicePrefix}/fees`, { params: stripEmptyObjects(params) })
  return response.data
}

const getMemberFeeById = async (id) => {
  const response = await api.get(`${servicePrefix}/fees/${id}`)
  return response.data
}

const addMemberFee = async (memberFee) => {
  const response = await api.post(`${servicePrefix}/fees`, memberFee)
  return response.data
}

const updateMemberFee = async (memberFee) => {
  const { id } = memberFee
  const response = await api.patch(`${servicePrefix}/fees/${id}`, memberFee)
  return response.data
}

const deleteMemberFee = async (id) => {
  const response = await api.delete(`${servicePrefix}/fees/${id}`)
  return response.data
}

const getMemberFeeHistory = async (memberId) => {
  const response = await api.get(`${servicePrefix}/fees/get-member-fee/${memberId}`)
  // console.log('service', response)
  return response.data
}
const getTotalMemberFee = async () => {
  const response = await api.get(`${servicePrefix}/fees/member-fee-total`)
  return response.data
}

//donation
const getAllDonation = async (params) => {
  const response = await api.get(`${servicePrefix}/donations`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDonationById = async (id) => {
  const response = await api.get(`${servicePrefix}/donations/${id}`)
  return response.data
}

const addDonation = async (donation) => {
  const response = await api.post(`${servicePrefix}/donations`, donation)
  return response.data
}

const updateDonation = async (donation) => {
  const { id } = donation
  const response = await api.patch(`${servicePrefix}/donations/${id}`, donation)
  return response.data
}

const deleteDonation = async (id) => {
  const response = await api.delete(`${servicePrefix}/donations/${id}`)
  return response.data
}

const getMemberDonationHistory = async (cid) => {
  const response = await api.get(`${servicePrefix}/donations/get-donation-by-cid/${cid}`)
  // console.log('service', response)
  return response.data
}

const getDonationCertificateByCid = async (cid, year) => {
  const response = await api.get(`${servicePrefix}/donations/tax-report/${cid}/${year}`)
  return response.data
}

const getDonationTotal = async () => {
  const response = await api.get(`${servicePrefix}/donations/donation-total`)
  return response.data
}

const CollectionTypeService = {
  //membership
  getAllMemberFee,
  getMemberFeeById,
  addMemberFee,
  updateMemberFee,
  deleteMemberFee,
  getMemberFeeHistory,
  getTotalMemberFee,

  //donation
  getAllDonation,
  getDonationById,
  addDonation,
  updateDonation,
  deleteDonation,
  getMemberDonationHistory,
  getDonationCertificateByCid,
  getDonationTotal
}

export default CollectionTypeService
