// ** Redux Imports
import MonkExpenseService from '@services/monk-expense'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllMonkExpense = createAsyncThunk('monkExpense/getAllMonkExpense', async (params, { rejectWithValue }) => {
  try {
    const response = await MonkExpenseService.getAllMonkExpense(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getMonkExpenseById = createAsyncThunk('monkExpense/getMonkExpenseById', async (id) => {
  const response = await MonkExpenseService.getMonkExpenseById(id)
  return response
})

export const addMonkExpense = createAsyncThunk('monkExpense/addMonkExpense', async (expense, { rejectWithValue }) => {
  try {
    const response = await MonkExpenseService.addMonkExpense(expense)

    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateMonkExpense = createAsyncThunk('monkExpense/updateMonkExpense', async (expense, { rejectWithValue }) => {
  console.log('data', expense)
  try {
    const response = await MonkExpenseService.updateMonkExpense(expense)

    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteMonkExpense = createAsyncThunk('monkExpense/deleteMonkExpense', async (id, { dispatch, getState }) => {
  await MonkExpenseService.deleteMonkExpense(id)
  await dispatch(getAllMonkExpense(getState()?.monkExpenses.params))
  return id
})

export const MonkExpenseSlice = createSlice({
  name: 'monk_expense',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualMonkExpense: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMonkExpense.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMonkExpenseById.fulfilled, (state, action) => {
        state.individualMonkExpense = action.payload
        state.loading = false
      })
      .addCase(getMonkExpenseById.pending, (state, action) => {
        state.individualMonkExpense = action.payload
        state.loading = true
      })
      .addCase(getMonkExpenseById.rejected, (state, action) => {
        state.individualMonkExpense = action.payload
        state.loading = false
      })
  }
})

export default MonkExpenseSlice.reducer
