import { lazy } from 'react'

const MemberReport = lazy(() => import('../../views/report/member/index'))
const MemberFeeReport = lazy(() => import('../../views/report/member-fee/index'))
const DonationReport = lazy(() => import('../../views/report/donation/index'))
const MonkReport = lazy(() => import('../../views/report/monk/index'))
const LeaveReport = lazy(() => import('../../views/report/leave/index'))
const TourReport = lazy(() => import('../../views/report/tour/index'))
const DeputationReport = lazy(() => import('../../views/report/deputation/index'))
const AbsentReport = lazy(() => import('../../views/report/absent/index'))
const TienReport = lazy(() => import('../../views/report/tien/index'))
const DesignationReport = lazy(() => import('../../views/report/designation/index'))
const PresentReport = lazy(() => import('../../views/report/present/index'))
const KurimReport = lazy(() => import('../../views/report/kurim/index'))

const reportRoutes = [
  {
    path: '/member-report',
    element: <MemberReport />,
    meta: {
      action: 'manage',
      resource: 'LhabangReport'
    }
  },
  {
    path: '/member-fee-report',
    element: <MemberFeeReport />,
    meta: {
      action: 'manage',
      resource: 'LhabangReport'
    }
  },
  {
    path: '/donation-report',
    element: <DonationReport />,
    meta: {
      action: 'manage',
      resource: 'LhabangReport'
    }
  },
  {
    path: '/leave-report',
    element: <LeaveReport />,
    meta: {
      action: 'manage',
      resource: 'LeaveReport'
    }
  },
  {
    path: '/tour-report',
    element: <TourReport />,
    meta: {
      action: 'manage',
      resource: 'TourReport'
    }
  },
  {
    path: '/deputation-report',
    element: <DeputationReport />,
    meta: {
      action: 'manage',
      resource: 'DeputationReport'
    }
  },
  {
    path: '/absent-report',
    element: <AbsentReport />,
    meta: {
      action: 'manage',
      resource: 'AbsentReport'
    }
  },
  {
    path: '/monk-report',
    element: <MonkReport />,
    meta: {
      action: 'manage',
      resource: 'MonkReport'
    }
  },
  {
    path: '/tien-report',
    element: <TienReport />,
    meta: {
      action: 'manage',
      resource: 'TienReport'
    }
  },
  {
    path: '/mdesignation-report',
    element: <DesignationReport />,
    meta: {
      action: 'manage',
      resource: 'DesignationReport'
    }
  },
  {
    path: '/presents',
    element: <PresentReport />,
    meta: {
      action: 'manage',
      resource: 'PresentReport'
    }
  },
  {
    path: '/kurims',
    element: <KurimReport />,
    meta: {
      action: 'manage',
      resource: 'PresentReport'
    }
  }
]

export default reportRoutes
