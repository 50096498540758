// ** Redux Imports
import MonkDesignationService from '@services/monk-designation'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllMonkDesignation = createAsyncThunk('monkDesignation/getAllMonkDesignation', async (params, { rejectWithValue }) => {
  try {
    const response = await MonkDesignationService.getAllMonkDesignation(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getMonkDesignationById = createAsyncThunk('monkDesignation/getMonkDesignationById', async (id) => {
  const response = await MonkDesignationService.getMonkDesignationById(id)
  return response
})

export const addMonkDesignation = createAsyncThunk('monkDesignation/addMonkDesignation', async (designation, { rejectWithValue }) => {
  try {
    const response = await MonkDesignationService.addMonkDesignation(designation)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateMonkDesignation = createAsyncThunk('monkDesignation/updateMonkDesignation', async (designation, { rejectWithValue }) => {
  try {
    const response = await MonkDesignationService.updateMonkDesignation(designation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteMonkDesignation = createAsyncThunk('monkDesignation/deleteMonkDesignation', async (id, { dispatch, getState }) => {
  await MonkDesignationService.deleteMonkDesignation(id)
  await dispatch(getAllMonkDesignation(getState()?.monkDesignations.params))
  return id
})

export const MonkDesignationSlice = createSlice({
  name: 'monk_designation',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualMonkDesignation: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMonkDesignation.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMonkDesignationById.fulfilled, (state, action) => {
        state.individualMonkDesignation = action.payload
        state.loading = false
      })
      .addCase(getMonkDesignationById.pending, (state, action) => {
        state.individualMonkDesignation = action.payload
        state.loading = true
      })
      .addCase(getMonkDesignationById.rejected, (state, action) => {
        state.individualMonkDesignation = action.payload
        state.loading = false
      })
  }
})

export default MonkDesignationSlice.reducer
