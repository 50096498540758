import { lazy } from 'react'

const WalletLists = lazy(() => import('../../views/wallet/list/index'))
const WalletAdd = lazy(() => import('../../views/wallet/add/index'))
const WalletFullDetails = lazy(() => import('../../views/wallet/add/addWallet'))
const WalletView = lazy(() => import('../../views/wallet/view/index'))
// const WalletUpdate = lazy(() => import('../../views/wallet/edit/index'))

//main wallet
const MainWalletView = lazy(() => import('../../views/wallet/main-wallet/index'))

//Transaction
// const TransactionList = lazy(() => import('../../views/wallet/'))

const WalletRoutes = [
  //main wallet
  {
    path: '/main-wallet',
    element: <MainWalletView />,
    meta: {
      action: 'read',
      resource: 'MainWallet'
    }
  },

  //Individual wallet
  {
    path: '/wallet/list',
    element: <WalletLists />,
    meta: {
      action: 'read',
      resource: 'Wallet'
    }
  },
  {
    path: '/wallet/list/add',
    element: <WalletAdd />,
    meta: {
      action: 'create',
      resource: 'Wallet'
    }
  },
  {
    path: '/wallet/list/add-full-details',
    element: <WalletFullDetails />,
    meta: {
      action: 'create',
      resource: 'Wallet'
    }
  },
  {
    path: '/wallet/list/view/:id',
    element: <WalletView />,
    meta: {
      action: 'read',
      resource: 'Wallet'
    }
  }
  //   {
  //     path: '/wallet/list/edit/:id',
  //     element: <WalletUpdate />,
  //     meta: {
  //       action: 'update',
  //       resource: 'User'
  //     }
  //   }
]

export default WalletRoutes
