import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//member
const getAllMember = async (params) => {
  const response = await api.get(`${servicePrefix}/members`, { params: stripEmptyObjects(params) })
  return response.data
}

const getMemberById = async (id) => {
  const response = await api.get(`${servicePrefix}/members/${id}`)
  return response.data
}

const addMember = async (member) => {
  const response = await api.post(`${servicePrefix}/members`, member)
  return response.data
}

const updateMember = async (member) => {
  const { id } = member
  const response = await api.patch(`${servicePrefix}/members/${id}`, member)
  return response.data
}

const deleteMember = async (id) => {
  const response = await api.delete(`${servicePrefix}/members/${id}`)
  return response.data
}

const searchMember = async (search) => {
  const response = await api.get(`${servicePrefix}/members/search-by-identity-no/${encodeURIComponent(search)}`)
  return response.data
}

const getCertificateByMemberId = async (cid, year) => {
  const response = await api.get(`${servicePrefix}/members/member-certificate/${cid}/${year}`)
  return response.data
}
const getTotalMemberFee = async (id) => {
  const response = await api.get(`${servicePrefix}/members/get-total-member-fee/${id}`)
  return response.data
}

const getTotalMemberDonation = async (id) => {
  const response = await api.get(`${servicePrefix}/members/get-total-donation/${id}`)
  return response.data
}

const getTotalFees = async () => {
  const response = await api.get(`${servicePrefix}/fees/member-fee-total`)
  return response.data
}
const getTotalMember = async () => {
  const response = await api.get(`${servicePrefix}/members/get-total-member`)
  return response.data
}

const getTotalSIMember = async () => {
  const response = await api.get(`${servicePrefix}/members/get-total-SI`)
  return response.data
}

const getTotalGeneralMember = async () => {
  const response = await api.get(`${servicePrefix}/members/get-total-general`)
  return response.data
}

// File Uploads

const accountFileUploads = async (data, year) => {
  const response = await api.post(`${servicePrefix}/account-file-uploads/${year}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const getAccountFileUploads = async (params) => {
  const response = await api.get(`${servicePrefix}/account-file-uploads`, { params: stripEmptyObjects(params) })
  return response.data
}

const deleteFileUploads = async (id) => {
  const response = await api.delete(`${servicePrefix}/account-file-uploads/${id}`)
  return response.data
}

const MembershipService = {
  //membership
  getAllMember,
  getMemberById,
  addMember,
  updateMember,
  deleteMember,
  searchMember,
  getCertificateByMemberId,
  getTotalMemberFee,
  getTotalMemberDonation,
  getTotalFees,
  getTotalMember,
  getTotalSIMember,
  getTotalGeneralMember,

  //File Uploads
  accountFileUploads,
  getAccountFileUploads,
  deleteFileUploads
}

export default MembershipService
