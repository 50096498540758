import { lazy } from 'react'

const UserLists = lazy(() => import('../../views/user/list/index'))
const UserAdd = lazy(() => import('../../views/user/add/index'))
const UserView = lazy(() => import('../../views/user/view/index'))
const UserUpdate = lazy(() => import('../../views/user/edit/index'))

const UserRoutes = [
  {
    path: '/user/list',
    element: <UserLists />,
    meta: {
      action: 'manage',
      resource: 'User'
    }
  },
  {
    path: '/user/list/add',
    element: <UserAdd />,
    meta: {
      action: 'create',
      resource: 'User'
    }
  },
  {
    path: '/user/list/view/:id',
    element: <UserView />,
    meta: {
      action: 'read',
      resource: 'User'
    }
  },
  {
    path: '/user/list/edit/:id',
    element: <UserUpdate />,
    meta: {
      action: 'update',
      resource: 'User'
    }
  }
]

export default UserRoutes
