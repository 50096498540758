import { lazy } from 'react'

const TienList = lazy(() => import('../../views/tien/list'))
const SearchMonk = lazy(() => import('../../views/tien/add/index'))
const AddTien = lazy(() => import('../../views/tien/add/AddTien'))
const ViewTien = lazy(() => import('../../views/tien/view/index'))
const EditTien = lazy(() => import('../../views/tien/edit/index'))

const KurimRoutes = [
  {
    path: '/tien/monk-search',
    element: <SearchMonk />,
    meta: {
      action: 'create',
      resource: 'Tien'
    }
  },
  {
    path: '/tien/monk-search/add',
    element: <AddTien />,
    meta: {
      action: 'create',
      resource: 'Tien'
    }
  },
  {
    path: '/tien/lists',
    element: <TienList />,
    meta: {
      action: 'read',
      resource: 'Tien'
    }
  },
  {
    path: '/tien/lists/view/:id',
    element: <ViewTien />,
    meta: {
      action: 'read',
      resource: 'Tien'
    }
  },
  {
    path: '/tien/lists/edit/:id',
    element: <EditTien />,
    meta: {
      action: 'update',
      resource: 'Tien'
    }
  }
]

export default KurimRoutes
