// ** Redux Imports
import AttendenceService from '@services/attendance'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllDailyAttendance = createAsyncThunk('absent/getAllDailyAttendance', async (params, { rejectWithValue }) => {
  try {
    const response = await AttendenceService.getAllDailyAttendance(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDailyAttendanceById = createAsyncThunk('absent/getDailyAttendanceById', async (id) => {
  const response = await AttendenceService.getDailyAttendanceById(id)
  return response
})

export const addDailyAttendance = createAsyncThunk('absent/addDailyAttendance', async (absent, { rejectWithValue }) => {
  try {
    const response = await AttendenceService.addDailyAttendance(absent)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateDailyAttendance = createAsyncThunk('absent/updateDailyAttendance', async (absent) => {
  const response = await AttendenceService.updateDailyAttendance(absent)
  return response
})

export const deleteDailyAttendance = createAsyncThunk('absent/deleteDailyAttendance', async (id, { dispatch, getState }) => {
  await AttendenceService.deleteDailyAttendance(id)
  await dispatch(getAllDailyAttendance(getState()?.dailyAttendances.params))
  return id
})

export const DailyAttendanceSlice = createSlice({
  name: 'daily_attendance',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDailyAttendance: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDailyAttendance.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDailyAttendanceById.fulfilled, (state, action) => {
        state.individualDailyAttendance = action.payload
        state.loading = false
      })
      .addCase(getDailyAttendanceById.pending, (state, action) => {
        state.individualDailyAttendance = action.payload
        state.loading = true
      })
      .addCase(getDailyAttendanceById.rejected, (state, action) => {
        state.individualDailyAttendance = action.payload
        state.loading = false
      })
  }
})

export default DailyAttendanceSlice.reducer
