// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import UserService from '../../../services/user'

export const getUsers = createAsyncThunk('user/getUsers', async (params, { rejectWithValue }) => {
  try {
    const response = await UserService.getUsers(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getUserById = createAsyncThunk('user/getUserById', async (id) => {
  const response = await UserService.getUserById(id)
  return response
})

export const addUser = createAsyncThunk('user/addUser', async (role, { rejectWithValue }) => {
  try {
    const response = await UserService.addUser(role)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateUser = createAsyncThunk('user/updateUser', async (role, { rejectWithValue }) => {
  try {
    const response = await UserService.updateUserIndividual(role)
    return response
  } catch (err) {
    console.log(err.response.data)
    rejectWithValue(err.response.data)
  }
})

export const deleteUser = createAsyncThunk('user/deleteUser', async (id, { dispatch, getState }) => {
  await UserService.deleteUser(id)
  await dispatch(getUsers(getState()?.users.params))
  return id
})

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualUser: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUserById.fulfilled, (state, action) => {
        state.individualUser = action.payload
        state.loading = false
      })
      .addCase(getUserById.pending, (state, action) => {
        state.individualUser = action.payload
        state.loading = true
      })
      .addCase(getUserById.rejected, (state, action) => {
        state.individualUser = action.payload
        state.loading = false
      })
  }
})

export default userSlice.reducer
