// ** Redux Imports
import AttendenceService from '@services/attendance'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllDeputation = createAsyncThunk('deputation/getAllDeputation', async (params, { rejectWithValue }) => {
  try {
    const response = await AttendenceService.getAllDeputation(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDeputationById = createAsyncThunk('deputation/getDeputationById', async (id) => {
  const response = await AttendenceService.getDeputationById(id)
  return response
})

export const addDeputation = createAsyncThunk('deputation/addDeputation', async (deputation, { rejectWithValue }) => {
  try {
    const response = await AttendenceService.addDeputation(deputation)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateDeputation = createAsyncThunk('deputation/updateDeputation', async (deputation) => {
  const response = await AttendenceService.updateDeputation(deputation)
  return response
})

export const deleteDeputation = createAsyncThunk('deputation/deleteDeputation', async (id, { dispatch, getState }) => {
  await AttendenceService.deleteDeputation(id)
  await dispatch(getAllDeputation(getState()?.deputations.params))
  return id
})

export const DeputationSlice = createSlice({
  name: 'deputation',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDeputation: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDeputation.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDeputationById.fulfilled, (state, action) => {
        state.individualDeputation = action.payload
        state.loading = false
      })
      .addCase(getDeputationById.pending, (state, action) => {
        state.individualDeputation = action.payload
        state.loading = true
      })
      .addCase(getDeputationById.rejected, (state, action) => {
        state.individualDeputation = action.payload
        state.loading = false
      })
  }
})

export default DeputationSlice.reducer
