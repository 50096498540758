// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/tien'

export const getTien = createAsyncThunk('tien/getTien', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllTiens(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getTienById = createAsyncThunk('tien/getTienById', async (id) => {
  const response = await MasterService.getTiensById(id)
  return response
})

export const addTien = createAsyncThunk('tien/addTien', async (tien, { rejectWithValue }) => {
  try {
    const response = await MasterService.addTiens(tien)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateTien = createAsyncThunk('tien/updateTien', async (tien, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateTiens(tien)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteTien = createAsyncThunk('tien/deleteTien', async (id, { dispatch, getState }) => {
  await MasterService.deleteTiens(id)
  await dispatch(getTien(getState()?.tiens.params))
  return id
})

export const tienSlice = createSlice({
  name: 'tien',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualTien: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTien.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTienById.fulfilled, (state, action) => {
        state.individualTien = action.payload
        state.loading = false
      })
      .addCase(getTienById.pending, (state, action) => {
        state.individualTien = action.payload
        state.loading = true
      })
      .addCase(getTienById.rejected, (state, action) => {
        state.individualTien = action.payload
        state.loading = false
      })
  }
})

export default tienSlice.reducer
