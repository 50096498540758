import { lazy } from 'react'

const DratshangList = lazy(() => import('../../views/dratshang-information/list'))
const AddDratshang = lazy(() => import('../../views/dratshang-information/add/index'))
const ViewDratshang = lazy(() => import('../../views/dratshang-information/view/index'))
const EditDratshang = lazy(() => import('../../views/dratshang-information/edit/index'))
const DratshangSearch = lazy(() => import('../../views/dratshang-information/search/search'))

const DratshangRoutes = [
  {
    path: '/dratshang/lists',
    element: <DratshangList />,
    meta: {
      action: 'read',
      resource: 'Dratshang'
    }
  },
  {
    path: '/dratshang/add',
    element: <AddDratshang />,
    meta: {
      action: 'create',
      resource: 'Dratshang'
    }
  },
  {
    path: '/dratshang/lists/view/:id',
    element: <ViewDratshang />,
    meta: {
      action: 'read',
      resource: 'Dratshang'
    }
  },
  {
    path: '/dratshang/lists/edit/:id',
    element: <EditDratshang />,
    meta: {
      action: 'update',
      resource: 'Dratshang'
    }
  },
  {
    path: '/dratshang/search',
    element: <DratshangSearch />,
    meta: {
      action: 'read',
      resource: 'Dratshang'
    }
  }
]

export default DratshangRoutes
