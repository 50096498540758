import { lazy } from 'react'

//deputation
const DeputationList = lazy(() => import('../../views/attendance/deputation/list/index'))
const MonkSearchDeputation = lazy(() => import('../../views/attendance/deputation/add/index'))
const AddDeputation = lazy(() => import('../../views/attendance/deputation/add/AddDeputation'))
const ViewDeputation = lazy(() => import('../../views/attendance/deputation/view/index'))
const EditDeputation = lazy(() => import('../../views/attendance/deputation/edit/index'))

//tour
const TourList = lazy(() => import('../../views/attendance/tour/list/index'))
const MonkSearchTour = lazy(() => import('../../views/attendance/tour/add/index'))
const AddTour = lazy(() => import('../../views/attendance/tour/add/AddTour'))
const ViewTour = lazy(() => import('../../views/attendance/tour/view/index'))
const EditTour = lazy(() => import('../../views/attendance/tour/edit/index'))

//tour
const DailyAttendanceList = lazy(() => import('../../views/attendance/daily-attendance/list/index'))
const MonkSearch = lazy(() => import('../../views/attendance/daily-attendance/add/index'))
const AddDailyAttendance = lazy(() => import('../../views/attendance/daily-attendance/add/AddDailyAttendance'))
const ViewDailyAttendance = lazy(() => import('../../views/attendance/daily-attendance/view/index'))
const EditDailyAttendance = lazy(() => import('../../views/attendance/daily-attendance/edit/index'))

//tour
const LeaveList = lazy(() => import('../../views/attendance/leave/list/index'))
const MonkSearchLeave = lazy(() => import('../../views/attendance/leave/add/index'))
const AddLeave = lazy(() => import('../../views/attendance/leave/add/addLeaveDetails'))
const ViewLeave = lazy(() => import('../../views/attendance/leave/view/index'))
const EditLeave = lazy(() => import('../../views/attendance/leave/edit/index'))
// const AddLeaveDetails = lazy(() => import('../../views/attendance/leave/add/addLeaveDetails'))

const AttendanceRoutes = [
  //deputation
  {
    path: '/deputation/monk-search',
    element: <MonkSearchDeputation />,
    meta: {
      action: 'create',
      resource: 'Deputation'
    }
  },
  {
    path: '/deputation/monk-search/add',
    element: <AddDeputation />,
    meta: {
      action: 'create',
      resource: 'Deputation'
    }
  },
  {
    path: '/deputation/lists',
    element: <DeputationList />,
    meta: {
      action: 'read',
      resource: 'Deputation'
    }
  },
  {
    path: '/deputation/lists/view/:id',
    element: <ViewDeputation />,
    meta: {
      action: 'read',
      resource: 'Deputation'
    }
  },
  {
    path: '/deputation/lists/edit/:id',
    element: <EditDeputation />,
    meta: {
      action: 'update',
      resource: 'Deputation'
    }
  },

  //tour
  {
    path: '/tour/monk-search',
    element: <MonkSearchTour />,
    meta: {
      action: 'create',
      resource: 'Tour'
    }
  },
  {
    path: '/tour/monk-search/add',
    element: <AddTour />,
    meta: {
      action: 'create',
      resource: 'Tour'
    }
  },
  {
    path: '/tour/lists',
    element: <TourList />,
    meta: {
      action: 'read',
      resource: 'Tour'
    }
  },
  {
    path: '/tour/lists/view/:id',
    element: <ViewTour />,
    meta: {
      action: 'read',
      resource: 'Tour'
    }
  },
  {
    path: '/tour/lists/edit/:id',
    element: <EditTour />,
    meta: {
      action: 'update',
      resource: 'Tour'
    }
  },

  //daily attendance
  {
    path: '/daily-attendance/monk-search',
    element: <MonkSearch />,
    meta: {
      action: 'create',
      resource: 'DailyAttendence'
    }
  },
  {
    path: '/daily-attendance/monk-search/add',
    element: <AddDailyAttendance />,
    meta: {
      action: 'create',
      resource: 'DailyAttendence'
    }
  },
  {
    path: '/daily-attendance/lists',
    element: <DailyAttendanceList />,
    meta: {
      action: 'read',
      resource: 'DailyAttendence'
    }
  },
  {
    path: '/daily-attendance/lists/view/:id',
    element: <ViewDailyAttendance />,
    meta: {
      action: 'read',
      resource: 'DailyAttendence'
    }
  },
  {
    path: '/daily-attendance/lists/edit/:id',
    element: <EditDailyAttendance />,
    meta: {
      action: 'update',
      resource: 'DailyAttendence'
    }
  },

  //leave
  {
    path: '/leave/monk-search',
    element: <MonkSearchLeave />,
    meta: {
      action: 'create',
      resource: 'Leave'
    }
  },

  {
    path: '/leave/monk-search/add',
    element: <AddLeave />,
    meta: {
      action: 'create',
      resource: 'Leave'
    }
  },

  {
    path: '/leave/lists',
    element: <LeaveList />,
    meta: {
      action: 'read',
      resource: 'Leave'
    }
  },
  {
    path: '/leave/lists/view/:id',
    element: <ViewLeave />,
    meta: {
      action: 'read',
      resource: 'Leave'
    }
  },
  {
    path: '/leave/lists/edit/:id',
    element: <EditLeave />,
    meta: {
      action: 'update',
      resource: 'Leave'
    }
  }
  // {
  //   path: '/leave/add/details/:id',
  //   element: <AddLeaveDetails />,
  //   meta: {
  //     action: 'create',
  //     resource: 'Administrations'
  //   }
  // }
]

export default AttendanceRoutes
