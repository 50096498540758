// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import WalletService from '../../../services/wallet'

export const getWallets = createAsyncThunk('wallet/getWallets', async (params, { rejectWithValue }) => {
  try {
    const response = await WalletService.getWallets(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getWalletById = createAsyncThunk('wallet/getWalletById', async (id) => {
  const response = await WalletService.getWalletById(id)
  return response
})

export const addWallet = createAsyncThunk('wallet/addWallet', async (wallet, { rejectWithValue }) => {
  try {
    const response = await WalletService.addWallet(wallet)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateWallet = createAsyncThunk('wallet/updateWallet', async (wallet, { rejectWithValue }) => {
  try {
    const response = await WalletService.updateWallet(wallet)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteWallet = createAsyncThunk('wallet/deleteWallet', async (id, { dispatch, getState }) => {
  await WalletService.deleteWallet(id)
  await dispatch(getWallets(getState()?.wallets.params))
  return id
})

export const walletSlice = createSlice({
  name: 'wallets',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualWallet: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWallets.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getWalletById.fulfilled, (state, action) => {
        state.individualWallet = action.payload
        state.loading = false
      })
      .addCase(getWalletById.pending, (state, action) => {
        state.individualWallet = action.payload
        state.loading = true
      })
      .addCase(getWalletById.rejected, (state, action) => {
        state.individualWallet = action.payload
        state.loading = false
      })
  }
})

export default walletSlice.reducer
