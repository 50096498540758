import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//country
const getAllCountry = async (params) => {
  const response = await api.get(`${servicePrefix}/countries`, { params: stripEmptyObjects(params) })
  return response.data
}

const getCountryAll = async () => {
  const response = await api.get(`${servicePrefix}/countries/all`)
  return response.data
}

const getCountryById = async (id) => {
  const response = await api.get(`${servicePrefix}/countries/${id}`)
  return response.data
}

const addCountry = async (country) => {
  const response = await api.post(`${servicePrefix}/countries`, country)
  return response.data
}

const updateCountry = async (country) => {
  const { id } = country
  const response = await api.patch(`${servicePrefix}/countries/${id}`, country)
  return response.data
}

const deleteCountry = async (id) => {
  const response = await api.delete(`${servicePrefix}/countries/${id}`)
  return response.data
}

//dzongkhag
const getAllDzongkhag = async (params) => {
  const response = await api.get(`${servicePrefix}/dzongkhags`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDzongkhagAll = async () => {
  const response = await api.get(`${servicePrefix}/dzongkhags/all`)
  return response.data
}

const getDzongkhagById = async (id) => {
  const response = await api.get(`${servicePrefix}/dzongkhags/${id}`)
  return response.data
}

const addDzongkhag = async (dzongkhag) => {
  const response = await api.post(`${servicePrefix}/dzongkhags`, dzongkhag)
  return response.data
}

const updateDzongkhag = async (dzongkhag) => {
  const { id } = dzongkhag
  const response = await api.patch(`${servicePrefix}/dzongkhags/${id}`, dzongkhag)
  return response.data
}

const deleteDzongkhag = async (id) => {
  const response = await api.delete(`${servicePrefix}/dzongkhags/${id}`)
  return response.data
}

//gewog
const getAllGewog = async (params) => {
  const response = await api.get(`${servicePrefix}/gewogs`, { params: stripEmptyObjects(params) })
  return response.data
}

const getGewogAll = async () => {
  const response = await api.get(`${servicePrefix}/gewogs/all`)
  return response.data
}

const getGewogById = async (id) => {
  const response = await api.get(`${servicePrefix}/gewogs/${id}`)
  return response.data
}

const addGewog = async (gewog) => {
  const response = await api.post(`${servicePrefix}/gewogs`, gewog)
  return response.data
}

const updateGewog = async (gewog) => {
  const { id } = gewog
  const response = await api.patch(`${servicePrefix}/gewogs/${id}`, gewog)
  return response.data
}

const deleteGewog = async (id) => {
  const response = await api.delete(`${servicePrefix}/gewogs/${id}`)
  return response.data
}

//fee-type
const getAllFeeType = async (params) => {
  const response = await api.get(`${servicePrefix}/fee-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getFeeTypeAll = async () => {
  const response = await api.get(`${servicePrefix}/fee-types/all`)
  // console.log(response)
  return response.data
}

const getFeeTypeById = async (id) => {
  const response = await api.get(`${servicePrefix}/fee-types/${id}`)
  return response.data
}

const addFeeType = async (feeType) => {
  const response = await api.post(`${servicePrefix}/fee-types`, feeType)
  return response.data
}

const updateFeeType = async (feeType) => {
  const { id } = feeType
  const response = await api.patch(`${servicePrefix}/fee-types/${id}`, feeType)
  return response.data
}

const deleteFeeType = async (id) => {
  const response = await api.delete(`${servicePrefix}/fee-types/${id}`)
  return response.data
}

//bank
const getAllBank = async (params) => {
  const response = await api.get(`${servicePrefix}/bank-names`, { params: stripEmptyObjects(params) })
  return response.data
}
const getBankAll = async () => {
  const response = await api.get(`${servicePrefix}/bank-names/all`)
  // console.log(response)
  return response.data
}

const getBankById = async (id) => {
  const response = await api.get(`${servicePrefix}/bank-names/${id}`)
  return response.data
}

const addBank = async (bank) => {
  const response = await api.post(`${servicePrefix}/bank-names`, bank)
  return response.data
}

const updateBank = async (bank) => {
  const { id } = bank
  const response = await api.patch(`${servicePrefix}/bank-names/${id}`, bank)
  return response.data
}

const deleteBank = async (id) => {
  const response = await api.delete(`${servicePrefix}/bank-names/${id}`)
  return response.data
}

//dratshang category
const getAllDratshangLevel = async (params) => {
  const response = await api.get(`${servicePrefix}/dratshang-masters`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDratshangLevelAll = async () => {
  const response = await api.get(`${servicePrefix}/dratshang-masters/all`)
  return response.data
}

const getDratshangLevelById = async (id) => {
  const response = await api.get(`${servicePrefix}/dratshang-masters/${id}`)
  return response.data
}

const addDratshangLevel = async (level) => {
  const response = await api.post(`${servicePrefix}/dratshang-masters`, level)
  return response.data
}

const updateDratshangLevel = async (level) => {
  const { id } = level
  const response = await api.patch(`${servicePrefix}/dratshang-masters/${id}`, level)
  return response.data
}

const deleteDratshangLevel = async (id) => {
  const response = await api.delete(`${servicePrefix}/dratshang-masters/${id}`)
  return response.data
}

//designation
const getAllDesignation = async (params) => {
  const response = await api.get(`${servicePrefix}/designations`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDesignationAll = async () => {
  const response = await api.get(`${servicePrefix}/designations/all`)
  // console.log(response)
  return response.data
}

const getDesignationById = async (id) => {
  const response = await api.get(`${servicePrefix}/designations/${id}`)
  return response.data
}

const addDesignation = async (designation) => {
  const response = await api.post(`${servicePrefix}/designations`, designation)
  return response.data
}

const updateDesignation = async (designation) => {
  const { id } = designation
  const response = await api.patch(`${servicePrefix}/designations/${id}`, designation)
  return response.data
}

const deleteDesignation = async (id) => {
  const response = await api.delete(`${servicePrefix}/designations/${id}`)
  return response.data
}

//grade-categories
const getAllGradeCategory = async (params) => {
  const response = await api.get(`${servicePrefix}/grade-categories`, { params: stripEmptyObjects(params) })
  return response.data
}

const getGradeCategoryAll = async () => {
  const response = await api.get(`${servicePrefix}/grade-categories/all`)
  // console.log(response)
  return response.data
}

const getGradeCategoryById = async (id) => {
  const response = await api.get(`${servicePrefix}/grade-categories/${id}`)
  return response.data
}

const addGradeCategory = async (gradeCategory) => {
  const response = await api.post(`${servicePrefix}/grade-categories`, gradeCategory)
  return response.data
}

const updateGradeCategory = async (gradeCategory) => {
  const { id } = gradeCategory
  const response = await api.patch(`${servicePrefix}/grade-categories/${id}`, gradeCategory)
  return response.data
}

const deleteGradeCategory = async (id) => {
  const response = await api.delete(`${servicePrefix}/grade-categories/${id}`)
  return response.data
}

//grade
const getAllGrade = async (params) => {
  const response = await api.get(`${servicePrefix}/grades`, { params: stripEmptyObjects(params) })
  return response.data
}

const getGradeAll = async () => {
  const response = await api.get(`${servicePrefix}/grades/all`)
  // console.log(response)
  return response.data
}

const getGradeById = async (id) => {
  const response = await api.get(`${servicePrefix}/grades/${id}`)
  return response.data
}

const addGrade = async (grade) => {
  const response = await api.post(`${servicePrefix}/grades`, grade)
  return response.data
}

const updateGrade = async (grade) => {
  const { id } = grade
  const response = await api.patch(`${servicePrefix}/grades/${id}`, grade)
  return response.data
}

const deleteGrade = async (id) => {
  const response = await api.delete(`${servicePrefix}/grades/${id}`)
  return response.data
}

//checklist-category
const getAllChecklistCategory = async (params) => {
  const response = await api.get(`${servicePrefix}/checklists`, { params: stripEmptyObjects(params) })
  return response.data
}

const getChecklistCategoryAll = async () => {
  const response = await api.get(`${servicePrefix}/checklists/all`)
  // console.log(response)
  return response.data
}

const getChecklistCategoryById = async (id) => {
  const response = await api.get(`${servicePrefix}/checklists/${id}`)
  return response.data
}

const addChecklistCategory = async (checklist) => {
  const response = await api.post(`${servicePrefix}/checklists`, checklist)
  return response.data
}

const updateChecklistCategory = async (checklist) => {
  const { id } = checklist
  const response = await api.patch(`${servicePrefix}/checklists/${id}`, checklist)
  return response.data
}

const deleteChecklistCategory = async (id) => {
  const response = await api.delete(`${servicePrefix}/checklists/${id}`)
  return response.data
}

const searchChecklistCategory = async (search) => {
  const response = await api.get(`${servicePrefix}/checklists/search-checklist/${encodeURIComponent(search)}`)
  return response.data
}

//checklist-item
const getAllChecklistItem = async (params) => {
  const response = await api.get(`${servicePrefix}/checklist-items`, { params: stripEmptyObjects(params) })
  return response.data
}

const getChecklistItemAll = async () => {
  const response = await api.get(`${servicePrefix}/checklist-items/all`)
  // console.log(response)
  return response.data
}

const getChecklistItemById = async (id) => {
  const response = await api.get(`${servicePrefix}/checklist-items/${id}`)
  return response.data
}

const addChecklistItem = async (checklist) => {
  const response = await api.post(`${servicePrefix}/checklist-items`, checklist)
  return response.data
}

const updateChecklistItem = async (checklist) => {
  const { id } = checklist
  const response = await api.patch(`${servicePrefix}/checklist-items/${id}`, checklist)
  return response.data
}

const deleteChecklistItem = async (id) => {
  const response = await api.delete(`${servicePrefix}/checklist-items/${id}`)
  return response.data
}

const searchChecklistItem = async (search) => {
  const response = await api.get(`${servicePrefix}/checklist-items/search-by-name/${encodeURIComponent(search)}`)
  return response.data
}

//donation type
const getAllDonationType = async (params) => {
  const response = await api.get(`${servicePrefix}/donation-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDonationTypeAll = async () => {
  const response = await api.get(`${servicePrefix}/donation-types/all`)
  return response.data
}

const getDonationTypeById = async (id) => {
  const response = await api.get(`${servicePrefix}/donation-types/${id}`)
  return response.data
}

const addDonationType = async (donationType) => {
  const response = await api.post(`${servicePrefix}/donation-types`, donationType)
  return response.data
}

const updateDonationType = async (donationType) => {
  const { id } = donationType
  const response = await api.patch(`${servicePrefix}/donation-types/${id}`, donationType)
  return response.data
}

const deleteDonationType = async (id) => {
  const response = await api.delete(`${servicePrefix}/donation-types/${id}`)
  return response.data
}

//dratshang asset type
const getAllAssetType = async (params) => {
  const response = await api.get(`${servicePrefix}/product-categories`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAssetTypeAll = async () => {
  const response = await api.get(`${servicePrefix}/product-categories/all`)
  return response.data
}

const getAssetTypeById = async (id) => {
  const response = await api.get(`${servicePrefix}/product-categories/${id}`)
  return response.data
}

const addAssetType = async (assetType) => {
  const response = await api.post(`${servicePrefix}/product-categories`, assetType)
  return response.data
}

const updateAssetType = async (assetType) => {
  const { id } = assetType
  const response = await api.patch(`${servicePrefix}/product-categories/${id}`, assetType)
  return response.data
}

const deleteAssetType = async (id) => {
  const response = await api.delete(`${servicePrefix}/product-categories/${id}`)
  return response.data
}

const MasterService = {
  //country
  getAllCountry,
  getCountryAll,
  getCountryById,
  addCountry,
  updateCountry,
  deleteCountry,

  //Dzongkhag
  getAllDzongkhag,
  getDzongkhagAll,
  getDzongkhagById,
  addDzongkhag,
  updateDzongkhag,
  deleteDzongkhag,

  //Gewog
  getAllGewog,
  getGewogAll,
  getGewogById,
  addGewog,
  updateGewog,
  deleteGewog,

  //fee-type
  getAllFeeType,
  getFeeTypeAll,
  getFeeTypeById,
  addFeeType,
  updateFeeType,
  deleteFeeType,

  //bank
  getAllBank,
  getBankAll,
  getBankById,
  addBank,
  updateBank,
  deleteBank,

  //dratshang category
  getAllDratshangLevel,
  getDratshangLevelAll,
  getDratshangLevelById,
  addDratshangLevel,
  updateDratshangLevel,
  deleteDratshangLevel,

  //designation
  getAllDesignation,
  getDesignationAll,
  getDesignationById,
  addDesignation,
  updateDesignation,
  deleteDesignation,

  //grade-categories
  getAllGradeCategory,
  getGradeCategoryAll,
  getGradeCategoryById,
  addGradeCategory,
  updateGradeCategory,
  deleteGradeCategory,

  //grade
  getAllGrade,
  getGradeAll,
  getGradeById,
  addGrade,
  updateGrade,
  deleteGrade,

  //checklist-category
  getAllChecklistCategory,
  getChecklistCategoryAll,
  getChecklistCategoryById,
  addChecklistCategory,
  updateChecklistCategory,
  deleteChecklistCategory,
  searchChecklistCategory,

  //checklist-item
  getAllChecklistItem,
  getChecklistItemAll,
  getChecklistItemById,
  addChecklistItem,
  updateChecklistItem,
  deleteChecklistItem,
  searchChecklistItem,

  //donation type
  getAllDonationType,
  getDonationTypeAll,
  getDonationTypeById,
  addDonationType,
  updateDonationType,
  deleteDonationType,

  //dratshang asset type
  getAllAssetType,
  getAssetTypeAll,
  getAssetTypeById,
  addAssetType,
  updateAssetType,
  deleteAssetType
}

export default MasterService
