// ** Icons Import
import { Menu, ArrowRight, EyeOff, Folder, LifeBuoy, Sh, ArrowRightield } from 'react-feather'

export default [
  {
    id: 'masters',
    title: 'Master',
    icon: <Folder size={20} />,
    children: [
      {
        id: 'countries',
        title: 'Country',
        icon: <ArrowRight size={12} />,
        navLink: '/country',
        resource: 'Country'
      },
      {
        id: 'dzongkhags',
        title: 'Dzongkhag',
        icon: <ArrowRight size={12} />,
        navLink: '/dzongkhag',
        resource: 'Dzongkhag'
      },
      {
        id: 'gewogs',
        title: 'Gewog',
        icon: <ArrowRight size={12} />,
        navLink: '/gewog',
        resource: 'Masters'
      },
      {
        id: 'fee-type',
        title: 'Fee Type',
        icon: <ArrowRight size={12} />,
        navLink: '/fee-type',
        resource: 'Masters'
      },
      {
        id: 'bank',
        title: 'Bank',
        icon: <ArrowRight size={12} />,
        navLink: '/bank',
        resource: 'Bank'
      },
      {
        id: 'dratshang-level',
        title: 'Dratshang level',
        icon: <ArrowRight size={12} />,
        navLink: '/dratshang-level',
        resource: 'DratshangLevel'
      },
      {
        id: 'designation',
        title: 'Designation',
        icon: <ArrowRight size={12} />,
        navLink: '/designation',
        resource: 'Designation'
      },
      {
        id: 'shedra-category',
        title: 'Shedra Category',
        icon: <ArrowRight size={12} />,
        navLink: '/grade-category',
        resource: 'ShedraCategory'
      },
      {
        id: 'shedra-class',
        title: 'Shedra Class',
        icon: <ArrowRight size={12} />,
        navLink: '/grade',
        resource: 'Shedra'
      },
      {
        id: 'checklist-category',
        title: 'Checklist Category',
        icon: <ArrowRight size={12} />,
        navLink: '/checklist-category',
        resource: 'ChecklistCategory'
      },
      {
        id: 'checklist',
        title: 'Checklist Item',
        icon: <ArrowRight size={12} />,
        navLink: '/checklist-item',
        resource: 'ChecklistItem'
      },
      {
        id: 'product-category',
        title: 'Product Category',
        icon: <ArrowRight size={12} />,
        navLink: '/asset-type',
        resource: 'AssetType'
      },
      {
        id: 'donation-type',
        title: 'Donation Type',
        icon: <ArrowRight size={12} />,
        navLink: '/donation-type',
        resource: 'DonationType'
      }
    ]
  }
]
