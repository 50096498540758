import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//monk-grade
const getAllMonkGrade = async (params) => {
  const response = await api.get(`${servicePrefix}/enrollments`, { params: stripEmptyObjects(params) })
  return response.data
}

const getMonkGradeAll = async () => {
  const response = await api.get(`${servicePrefix}/enrollments/all`)
  return response.data
}

const getMonkGradeById = async (id) => {
  const response = await api.get(`${servicePrefix}/enrollments/${id}`)
  return response.data
}

const addMonkGrade = async (grade) => {
  // console.log(grade)
  const response = await api.post(`${servicePrefix}/enrollments`, grade)
  return response.data
}

const updateMonkGrade = async (grade) => {
  const { id } = grade
  const response = await api.patch(`${servicePrefix}/enrollments/${id}`, grade)
  return response.data
}

const deleteMonkGrade = async (id) => {
  const response = await api.delete(`${servicePrefix}/enrollments/${id}`)
  return response.data
}

const viewGrade = async (dratshang, grade, year) => {
  const response = await api.get(`${servicePrefix}/enrollments/monk-list/${encodeURIComponent(dratshang)}/${encodeURIComponent(grade)}/${encodeURIComponent(year)}`)
  return response.data
}

const MonkGradeService = {
  getAllMonkGrade,
  getMonkGradeAll,
  getMonkGradeById,
  addMonkGrade,
  updateMonkGrade,
  deleteMonkGrade,
  viewGrade
}

export default MonkGradeService
