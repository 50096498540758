import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//monk-designation
const getAllMonkDesignation = async (params) => {
  const response = await api.get(`${servicePrefix}/monk-designations`, { params: stripEmptyObjects(params) })
  return response.data
}

// const getMonkDesignations = async () => {
//   const response = await api.get(`${servicePrefix}/designations/all`)
//   return response.data
// }

const getMonkDesignationById = async (id) => {
  const response = await api.get(`${servicePrefix}/monk-designations/${id}`)
  return response.data
}

const addMonkDesignation = async (designation) => {
  const response = await api.post(`${servicePrefix}/monk-designations`, designation)
  return response.data
}

const updateMonkDesignation = async (designation) => {
  const { id } = designation
  const response = await api.patch(`${servicePrefix}/monk-designations/${id}`, designation)
  return response.data
}

const deleteMonkDesignation = async (id) => {
  const response = await api.delete(`${servicePrefix}/monk-designations/${id}`)
  return response.data
}

const MonkDesignationService = {
  getAllMonkDesignation,
  getMonkDesignationById,
  addMonkDesignation,
  updateMonkDesignation,
  deleteMonkDesignation
}

export default MonkDesignationService
