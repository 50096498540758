import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//dratshang
const getAllDratshang = async (params) => {
  const response = await api.get(`${servicePrefix}/dratshangs`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDratshangAll = async () => {
  const response = await api.get(`${servicePrefix}/dratshangs/all`)
  return response.data
}

const getDratshangById = async (id) => {
  const response = await api.get(`${servicePrefix}/dratshangs/${id}`)
  return response.data
}

const addDratshang = async (dratshang) => {
  const response = await api.post(`${servicePrefix}/dratshangs`, dratshang)
  return response.data
}

const updateDratshang = async (dratshang) => {
  const { id } = dratshang
  const response = await api.patch(`${servicePrefix}/dratshangs/${id}`, dratshang)
  return response.data
}

const deleteDratshang = async (id) => {
  const response = await api.delete(`${servicePrefix}/dratshangs/${id}`)
  return response.data
}

//search pis type of individual
const searchDratshang = async (search) => {
  const response = await api.get(`${servicePrefix}/dratshangs/search-dratshang/${encodeURIComponent(search)}`)
  return response.data
}

const DratshangService = {
  getAllDratshang,
  getDratshangAll,
  getDratshangById,
  addDratshang,
  updateDratshang,
  deleteDratshang,
  searchDratshang
}

export default DratshangService
