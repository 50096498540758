import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

const getAllMonkExpense = async (params) => {
  const response = await api.get(`${servicePrefix}/expenses`, { params: stripEmptyObjects(params) })
  return response.data
}

const getMonkExpenseById = async (id) => {
  const response = await api.get(`${servicePrefix}/expenses/${id}`)
  return response.data
}

const addMonkExpense = async (expense) => {
  const response = await api.post(`${servicePrefix}/expenses`, expense)
  return response.data
}

const updateMonkExpense = async (expense) => {
  console.log('ll', expense)
  const { id } = expense
  const response = await api.patch(`${servicePrefix}/expenses/${id}`, expense)
  return response.data
}

const deleteMonkExpense = async (id) => {
  const response = await api.delete(`${servicePrefix}/expenses/${id}`)
  return response.data
}

const MonkExpenseService = {
  getAllMonkExpense,
  getMonkExpenseById,
  addMonkExpense,
  updateMonkExpense,
  deleteMonkExpense
}

export default MonkExpenseService
