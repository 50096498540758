import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//get  kurim
const getKurims = async (params) => {
  const response = await api.get(`${servicePrefix}/kurims`, { params: stripEmptyObjects(params) })
  return response.data
}

//get one kurim
const getKurimById = async (id) => {
  const response = await api.get(`${servicePrefix}/kurims/${id}`)
  return response.data
}

//add kurim
const addKurim = async (kurim) => {
  try {
    const response = await api.post(`${servicePrefix}/kurims`, kurim)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const updateKurim = async (kurim) => {
  const { id } = kurim
  console.log('hhhh', id)
  const response = await api.patch(`${servicePrefix}/kurims/${id}`, kurim)
  console.log('fff', response)
  return response.data
}

const searchKurim = async (search) => {
  const response = await api.get(`${servicePrefix}/kurims/search-by-address/${encodeURIComponent(search)}`)
  return response.data
}

const deleteKurim = async (id) => {
  const response = await api.delete(`${servicePrefix}/kurims/${id}`)
  return response.data
}

const deleteMonksInKurim = async (data) => {
  const response = await api.delete(`${servicePrefix}/kurims/delete-monk-in-kurim/${data?.data?.kurimId}/${data?.data?.monkId}`)
  return response.data
}

const KurimService = {
  getKurims,
  getKurimById,
  addKurim,
  updateKurim,
  searchKurim,
  deleteKurim,
  deleteMonksInKurim
}

export default KurimService
