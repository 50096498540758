import { lazy } from 'react'

const MonkExpenseList = lazy(() => import('../../views/monk-expense/list/index'))
const AddMonkExpense = lazy(() => import('../../views/monk-expense/add/AddMonkExpense'))
const SearchMonk = lazy(() => import('../../views/monk-expense/add/index'))
const ViewMonkExpense = lazy(() => import('../../views/monk-expense/view/index'))
const EditMonkExpense = lazy(() => import('../../views/monk-expense/edit/index'))

const MonkExpenseRoutes = [
  {
    path: '/monk-expense/monk-search',
    element: <SearchMonk />,
    meta: {
      action: 'create',
      resource: 'Expense'
    }
  },
  {
    path: '/monk-expense/monk-search/add',
    element: <AddMonkExpense />,
    meta: {
      action: 'create',
      resource: 'Expense'
    }
  },
  {
    path: '/monk-expense/lists',
    element: <MonkExpenseList />,
    meta: {
      action: 'read',
      resource: 'Expense'
    }
  },
  {
    path: '/monk-expense/lists/view/:id',
    element: <ViewMonkExpense />,
    meta: {
      action: 'read',
      resource: 'Expense'
    }
  },
  {
    path: '/monk-expense/lists/edit/:id',
    element: <EditMonkExpense />,
    meta: {
      action: 'update',
      resource: 'Expense'
    }
  }
]

export default MonkExpenseRoutes
