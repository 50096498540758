import { Grid, Shield, Circle, ArrowRight } from 'react-feather'

export default [
  {
    id: 'roles-permissions',
    title: 'Roles & Permissions',
    icon: <Shield size={20} />,
    children: [
      {
        id: 'roles',
        title: 'Roles',
        icon: <ArrowRight size={12} />,
        navLink: '/role/list',
        resource: 'Roles'
      },
      {
        id: 'permissions',
        title: 'Permissions',
        icon: <ArrowRight size={12} />,
        navLink: '/permission/list',
        resource: 'Permissions'
      }
    ]
  }
]
