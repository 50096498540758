import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//monk
const getAllMonks = async (params) => {
  const response = await api.get(`${servicePrefix}/monks`, { params: stripEmptyObjects(params) })
  return response.data
}

const getMonkAll = async () => {
  const response = await api.get(`${servicePrefix}/monks/all`)
  return response.data
}

const getMonkById = async (id) => {
  const response = await api.get(`${servicePrefix}/monks/${id}`)
  return response.data
}

const addMonk = async (monk) => {
  const response = await api.post(`${servicePrefix}/monks`, monk)
  return response.data
}

const updateMonk = async (monk) => {
  const { id } = monk
  const response = await api.patch(`${servicePrefix}/monks/${id}`, monk)
  return response.data
}

const deleteMonk = async (id) => {
  const response = await api.delete(`${servicePrefix}/monks/${id}`)
  return response.data
}

const searchMonk = async (search) => {
  const response = await api.get(`${servicePrefix}/monks/search-by-identityno-tckno-mobileno/${encodeURIComponent(search)}`)
  return response.data
}

const getMonkKurim = async (monkId) => {
  const response = await api.get(`${servicePrefix}/monks/get-kurims/${monkId}`)
  // console.log('service', response)
  return response.data
}

const getMonkTour = async (monkId) => {
  const response = await api.get(`${servicePrefix}/monks/get-tours/${monkId}`)
  // console.log('service', response)
  return response.data
}

const getMonkAbsent = async (monkId) => {
  const response = await api.get(`${servicePrefix}/monks/get-absents/${monkId}`)
  // console.log('service', response)
  return response.data
}

const getMonkDeputation = async (monkId) => {
  const response = await api.get(`${servicePrefix}/monks/get-deputations/${monkId}`)
  // console.log('service', response)
  return response.data
}

const getMonkLeave = async (monkId) => {
  const response = await api.get(`${servicePrefix}/monks/get-leaves/${monkId}`)
  // console.log('service', response)
  return response.data
}

const getMonkExpense = async (monkId) => {
  const response = await api.get(`${servicePrefix}/monks/get-expenses/${monkId}`)
  // console.log('service', response)
  return response.data
}

const MonkInformationService = {
  //monk
  getAllMonks,
  getMonkAll,
  getMonkById,
  addMonk,
  updateMonk,
  deleteMonk,
  searchMonk,
  getMonkKurim,
  getMonkAbsent,
  getMonkLeave,
  getMonkTour,
  getMonkDeputation,
  getMonkExpense
}

export default MonkInformationService
