// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/masters'

export const getGewog = createAsyncThunk('gewog/getGewog', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllGewog(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getGewogById = createAsyncThunk('gewog/getGewogById', async (id) => {
  const response = await MasterService.getGewogById(id)
  return response
})

export const addGewog = createAsyncThunk('gewog/addGewog', async (gewog, { rejectWithValue }) => {
  try {
    const response = await MasterService.addGewog(gewog)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateGewog = createAsyncThunk('gewog/updateGewog', async (gewog, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateGewog(gewog)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteGewog = createAsyncThunk('gewog/deleteGewog', async (id, { dispatch, getState }) => {
  await MasterService.deleteGewog(id)
  await dispatch(getGewog(getState()?.gewogs.params))
  return id
})

export const gewogSlice = createSlice({
  name: 'gewog',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualGewog: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGewog.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getGewogById.fulfilled, (state, action) => {
        state.individualGewog = action.payload
        state.loading = false
      })
      .addCase(getGewogById.pending, (state, action) => {
        state.individualGewog = action.payload
        state.loading = true
      })
      .addCase(getGewogById.rejected, (state, action) => {
        state.individualGewog = action.payload
        state.loading = false
      })
  }
})

export default gewogSlice.reducer
