import { lazy } from 'react'

//monk information
const MonkInformationList = lazy(() => import('../../views/monk-information/monk/list/index'))
const AddMonkInformation = lazy(() => import('../../views/monk-information/monk/add/index'))
const EditMonkInformation = lazy(() => import('../../views/monk-information/monk/edit/index'))
const ViewMonkInformation = lazy(() => import('../../views/monk-information/monk/view/index'))
const SearchMonkInformation = lazy(() => import('../../views/monk-information/search/search'))
const MonkKurim = lazy(() => import('../../views/monk-information/monk/view/KurimHistory'))
const MonkTour = lazy(() => import('../../views/monk-information/monk/view/TourHistory'))
const MonkLeave = lazy(() => import('../../views/monk-information/monk/view/LeaveHistory'))
const MonkDeputation = lazy(() => import('../../views/monk-information/monk/view/DeputationHistory'))
const MonkAbsent = lazy(() => import('../../views/monk-information/monk/view/AbsentHistory'))
const MonkExpense = lazy(() => import('../../views/monk-information/monk/view/ExpenseHistory'))
const MonkTien = lazy(() => import('../../views/monk-information/monk/view/TienHistory'))
const MonkDesignation = lazy(() => import('../../views/monk-information/monk/view/DesignationHistory'))

const MonkInformationRoutes = [
  //monk information
  {
    path: '/monk-information/lists',
    element: <MonkInformationList />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },

  {
    path: '/monk-information/add',
    element: <AddMonkInformation />,
    meta: {
      action: 'create',
      resource: 'Monk'
    }
  },

  {
    path: '/monk-information/lists/edit/:id',
    element: <EditMonkInformation />,
    meta: {
      action: 'update',
      resource: 'Monk'
    }
  },

  {
    path: '/monk-information/lists/view/:id',
    element: <ViewMonkInformation />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },

  {
    path: '/monk-information/search',
    element: <SearchMonkInformation />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },

  {
    path: '/monk-information/lists/view/:id/kurims',
    element: <MonkKurim />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },
  {
    path: '/monk-information/lists/view/:id/tours',
    element: <MonkTour />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },
  {
    path: '/monk-information/lists/view/:id/leaves',
    element: <MonkLeave />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },
  {
    path: '/monk-information/lists/view/:id/deputations',
    element: <MonkDeputation />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },
  {
    path: '/monk-information/lists/view/:id/absents',
    element: <MonkAbsent />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },
  {
    path: '/monk-information/lists/view/:id/expenses',
    element: <MonkExpense />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },
  {
    path: '/monk-information/lists/view/:id/tiens',
    element: <MonkTien />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  },
  {
    path: '/monk-information/lists/view/:id/designations',
    element: <MonkDesignation />,
    meta: {
      action: 'read',
      resource: 'Monk'
    }
  }
]

export default MonkInformationRoutes
