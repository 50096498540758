// ** Redux Imports
import MonkGradeService from '@services/monk-grade'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllMonkGrade = createAsyncThunk('monkGrade/getAllMonkGrade', async (params) => {
  const response = await MonkGradeService.getAllMonkGrade(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getMonkGradeById = createAsyncThunk('monkGrade/getMonkGradeById', async (id) => {
  const response = await MonkGradeService.getMonkGradeById(id)
  return response
})

export const addMonkGrade = createAsyncThunk('monkGrade/addMonkGrade', async (grade, { rejectWithValue }) => {
  try {
    const response = await MonkGradeService.addMonkGrade(grade)
    return response
  } catch (err) {
    // console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateMonkGrade = createAsyncThunk('monkGrade/updateMonkGrade', async (grade) => {
  const response = await MonkGradeService.updateMonkGrade(grade)
  return response
})

export const deleteMonkGrade = createAsyncThunk('monkGrade/deleteMonkGrade', async (id, { dispatch, getState }) => {
  await MonkGradeService.deleteMonkGrade(id)
  await dispatch(getAllMonkGrade(getState()?.monkGrades.params))
  return id
})

export const MonkGradeSlice = createSlice({
  name: 'monk_grade',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualMonkGrade: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMonkGrade.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMonkGradeById.fulfilled, (state, action) => {
        state.individualMonkGrade = action.payload
        state.loading = false
      })
      .addCase(getMonkGradeById.pending, (state, action) => {
        state.individualMonkGrade = action.payload
        state.loading = true
      })
      .addCase(getMonkGradeById.rejected, (state, action) => {
        state.individualMonkGrade = action.payload
        state.loading = false
      })
  }
})

export default MonkGradeSlice.reducer
