// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CollectionTypeService from '@services/collection-type'

export const getAllMemberFee = createAsyncThunk('memberFee/getAllMemberFee', async (params, { rejectWithValue }) => {
  try {
    const response = await CollectionTypeService.getAllMemberFee(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getMemberFeeById = createAsyncThunk('memberFee/getMemberFeeById', async (id) => {
  const response = await CollectionTypeService.getMemberFeeById(id)
  return response
})

export const addMemberFee = createAsyncThunk('memberFee/addMemberFee', async (memberFee, { rejectWithValue }) => {
  try {
    const response = await CollectionTypeService.addMemberFee(memberFee)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateMemberFee = createAsyncThunk('memberFee/updateMemberFee', async (memberFee, { rejectWithValue }) => {
  try {
    const response = await CollectionTypeService.updateMemberFee(memberFee)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteMemberFee = createAsyncThunk('memberFee/deleteMemberFee', async (id, { dispatch, getState }) => {
  await CollectionTypeService.deleteMemberFee(id)
  await dispatch(getAllMemberFee(getState()?.memberFees.params))
  return id
})

export const memberFeeSlice = createSlice({
  name: 'member_fee',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualMemberFee: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMemberFee.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMemberFeeById.fulfilled, (state, action) => {
        state.individualMemberFee = action.payload
        state.loading = false
      })
      .addCase(getMemberFeeById.pending, (state, action) => {
        state.individualMemberFee = action.payload
        state.loading = true
      })
      .addCase(getMemberFeeById.rejected, (state, action) => {
        state.individualMemberFee = action.payload
        state.loading = false
      })
  }
})

export default memberFeeSlice.reducer
