// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/masters'

export const getAllChecklistItem = createAsyncThunk('checklist/getAllChecklistItem', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllChecklistItem(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getChecklistItemById = createAsyncThunk('checklist/getChecklistItemById', async (id) => {
  const response = await MasterService.getChecklistItemById(id)
  return response
})

export const addChecklistItem = createAsyncThunk('checklist/addChecklistItem', async (checklist, { rejectWithValue }) => {
  try {
    const response = await MasterService.addChecklistItem(checklist)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateChecklistItem = createAsyncThunk('checklist/updateChecklistItem', async (checklist, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateChecklistItem(checklist)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteChecklistItem = createAsyncThunk('checklist/deleteChecklistItem', async (id, { dispatch, getState }) => {
  await MasterService.deleteChecklistItem(id)
  await dispatch(getAllChecklistItem(getState()?.checklistItems.params))
  return id
})

export const checklistItemSlice = createSlice({
  name: 'checklist_item',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualChecklistItem: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllChecklistItem.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getChecklistItemById.fulfilled, (state, action) => {
        state.individualChecklistItem = action.payload
        state.loading = false
      })
      .addCase(getChecklistItemById.pending, (state, action) => {
        state.individualChecklistItem = action.payload
        state.loading = true
      })
      .addCase(getChecklistItemById.rejected, (state, action) => {
        state.individualChecklistItem = action.payload
        state.loading = false
      })
  }
})

export default checklistItemSlice.reducer
