import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

const getUsers = async (params) => {
  const response = await api.get(`${servicePrefix}/users`, { params: stripEmptyObjects(params) })
  return response.data
}

const getUserById = async (id) => {
  const response = await api.get(`${servicePrefix}/users/${id}`)
  return response.data
}

const addUser = async (user) => {
  const response = await api.post(`${servicePrefix}/users`, user)
  return response.data
}

const updateUserIndividual = async (roles) => {
  const { id } = roles
  const response = await api.patch(`${servicePrefix}/users/${id}`, stripEmptyObjects(roles))
  return response.data
}

const deleteUser = async (id) => {
  const response = await api.delete(`${servicePrefix}/users/${id}`)
  return response.data
}

const UserService = {
  getUsers,
  getUserById,
  addUser,
  updateUserIndividual,
  deleteUser
}

export default UserService
