import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

const getWallets = async (params) => {
  const response = await api.get(`${servicePrefix}/wallets`, { params: stripEmptyObjects(params) })
  return response.data
}

const getWalletById = async (id) => {
  const response = await api.get(`${servicePrefix}/wallets/${id}`)
  return response.data
}

const getDepositByWalletId = async (id) => {
  const response = await api.get(`${servicePrefix}/wallets/get-deposits/${id}`)
  return response.data
}

const getWithdrawalByWalletId = async (id) => {
  const response = await api.get(`${servicePrefix}/wallets/get-withdrawals/${id}`)
  return response.data
}

const getTotalDepositByWalletId = async (id) => {
  const response = await api.get(`${servicePrefix}/wallets/get-total-deposit/${id}`)
  return response.data
}

const getTotalWithdrawalByWalletId = async (id) => {
  const response = await api.get(`${servicePrefix}/wallets/get-total-withdraw/${id}`)
  return response.data
}

const addWallet = async (wallet) => {
  const response = await api.post(`${servicePrefix}/wallets`, wallet)
  return response.data
}

const updateWalletIndividual = async (wallet) => {
  const { id } = wallet
  const response = await api.patch(`${servicePrefix}/wallets/${id}`, stripEmptyObjects(wallet))
  return response.data
}

const getWalletByMonkId = async (id) => {
  const response = await api.get(`${servicePrefix}/wallets/get-wallet/${id}`)
  return response.data
}

const deleteWallet = async (id) => {
  const response = await api.delete(`${servicePrefix}/wallets/${id}`)
  return response.data
}

//Transaction Api

const getTransactions = async (params) => {
  const response = await api.get(`${servicePrefix}/transactions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getTransactionById = async (id) => {
  const response = await api.get(`${servicePrefix}/transactions/${id}`)
  return response.data
}

const addTransaction = async (transaction) => {
  const response = await api.post(`${servicePrefix}/transactions`, transaction)
  return response.data
}

const updateTransactionIndividual = async (wallet) => {
  const { id } = wallet
  const response = await api.patch(`${servicePrefix}/wallets/${id}`, stripEmptyObjects(wallet))
  return response.data
}

const getWalletByWalletId = async (id) => {
  const response = await api.get(`${servicePrefix}/transactions/get-wallet/${id}`)
  return response.data
}

const getTrasactionTypeByWalletId = async (id) => {
  const response = await api.get(`${servicePrefix}/transactions/get-total-transaction-type/${id}`)
  return response.data
}

const getTransactionTotal = async () => {
  const response = await api.get(`${servicePrefix}/transactions/get-total-transactions`)
  return response.data
}

const getMainWallet = async () => {
  const response = await api.get(`${servicePrefix}/main-wallets`)
  return response.data
}

const deleteTransaction = async (id) => {
  const response = await api.delete(`${servicePrefix}/transactions/${id}`)
  return response.data
}

const WalletService = {
  getWallets,
  getWalletById,
  addWallet,
  updateWalletIndividual,
  getDepositByWalletId,
  getWithdrawalByWalletId,
  getTotalDepositByWalletId,
  getTotalWithdrawalByWalletId,
  getWalletByMonkId,
  deleteWallet,

  //Transaction
  getTransactions,
  getTransactionById,
  addTransaction,
  updateTransactionIndividual,
  getWalletByWalletId,
  getTrasactionTypeByWalletId,
  getTransactionTotal,
  deleteTransaction,

  //main wallet
  getMainWallet
}

export default WalletService
