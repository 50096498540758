import { lazy } from 'react'

//membership
const MemberList = lazy(() => import('../../views/membership/member/list/index'))
const AddMember = lazy(() => import('../../views/membership/member/add/index'))
const EditMember = lazy(() => import('../../views/membership/member/edit/index'))
const ViewMember = lazy(() => import('../../views/membership/member/view/index'))
const SearchMember = lazy(() => import('../../views/membership/search/search'))

//upload file
const UploadFile = lazy(() => import('../../views/account-file-upload/index'))
const UploadFileList = lazy(() => import('../../views/account-file-upload/list/index'))

const Membership = [
  //membership
  {
    path: '/member/lists',
    element: <MemberList />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/member/add',
    element: <AddMember />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/member/lists/edit/:id',
    element: <EditMember />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },

  {
    path: '/member/lists/view/:id',
    element: <ViewMember />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },
  {
    path: '/member/search',
    element: <SearchMember />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },
  {
    path: '/account-file-upload/add',
    element: <UploadFile />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  },
  {
    path: '/account-file-upload',
    element: <UploadFileList />,
    meta: {
      action: 'manage',
      resource: 'Members'
    }
  }
]

export default Membership
