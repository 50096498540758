import { lazy } from 'react'

const RoleLists = lazy(() => import('../../views/role/list/index'))
const RoleAdd = lazy(() => import('../../views/role/add/index'))
const RoleUpdate = lazy(() => import('../../views/role/edit/index'))

const RoleRoutes = [
  {
    path: '/role/list',
    element: <RoleLists />,
    meta: {
      action: 'manage',
      resource: 'Roles'
    }
  },
  {
    path: '/role/list/add',
    element: <RoleAdd />,
    meta: {
      action: 'create',
      resource: 'Roles'
    }
  },
  {
    path: '/role/list/edit/:id',
    element: <RoleUpdate />,
    meta: {
      action: 'update',
      resource: 'Roles'
    }
  }
]

export default RoleRoutes
