import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

//dratshang asset
const getAllDratshangAsset = async (params) => {
  const response = await api.get(`${servicePrefix}/dratshang-assets`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDratshangAssetById = async (id) => {
  const response = await api.get(`${servicePrefix}/dratshang-assets/${id}`)
  return response.data
}

const addDratshangAsset = async (dratshangAsset) => {
  const response = await api.post(`${servicePrefix}/dratshang-assets`, dratshangAsset)
  return response.data
}

const updateDratshangAsset = async (dratshangAsset) => {
  const { id } = dratshangAsset
  const response = await api.patch(`${servicePrefix}/dratshang-assets/${id}`, dratshangAsset)
  return response.data
}

const deleteDratshangAsset = async (id) => {
  const response = await api.delete(`${servicePrefix}/dratshang-assets/${id}`)
  return response.data
}

//Product

const getAllProduct = async (params) => {
  const response = await api.get(`${servicePrefix}/products`, { params: stripEmptyObjects(params) })
  return response.data
}

const getProductById = async (id) => {
  const response = await api.get(`${servicePrefix}/products/${id}`)
  return response.data
}

const addProduct = async (product) => {
  const response = await api.post(`${servicePrefix}/products`, product)
  return response.data
}

const getProduct = async () => {
  const response = await api.get(`${servicePrefix}/products`)
  return response.data
}

const updateProduct = async (product) => {
  const { id } = product
  const response = await api.patch(`${servicePrefix}/products/${id}`, product)
  return response.data
}

const deleteProduct = async (id) => {
  const response = await api.delete(`${servicePrefix}/products/${id}`)
  return response.data
}

//Accessory

const getAllAccessory = async (params) => {
  const response = await api.get(`${servicePrefix}/accessories`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAccessoryById = async (id) => {
  const response = await api.get(`${servicePrefix}/accessories/${id}`)
  return response.data
}

const addAccessory = async (accessories) => {
  const response = await api.post(`${servicePrefix}/accessories`, accessories)
  return response.data
}

const getAccessory = async () => {
  const response = await api.get(`${servicePrefix}/accessories`)
  return response.data
}

const updateAccessory = async (accessories) => {
  const { id } = accessories
  const response = await api.patch(`${servicePrefix}/accessories/${id}`, accessories)
  return response.data
}

const deleteAccessory = async (id) => {
  const response = await api.delete(`${servicePrefix}/accessories/${id}`)
  return response.data
}

//Assigns

const getAllAssign = async (params) => {
  const response = await api.get(`${servicePrefix}/assigns`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAssignById = async (id) => {
  const response = await api.get(`${servicePrefix}/assigns/${id}`)
  return response.data
}

const addAssign = async (assigns) => {
  const response = await api.post(`${servicePrefix}/assigns`, assigns)
  return response.data
}

const addAssignAndUpdateAssetStatus = async (assigns) => {
  const response = await api.post(`${servicePrefix}/assigns/post-and-update-asset`, assigns)
  return response.data
}

const getAssign = async () => {
  const response = await api.get(`${servicePrefix}/assigns`)
  return response.data
}

const updateAssign = async (assigns) => {
  const { id } = assigns
  const response = await api.patch(`${servicePrefix}/assigns/${id}`, assigns)
  return response.data
}

const deleteAssign = async (id) => {
  const response = await api.delete(`${servicePrefix}/assigns/${id}`)
  return response.data
}

const getAssignBasedAccessory = async (id) => {
  const response = await api.get(`${servicePrefix}/assigns/accessory-log/${id}`)
  return response.data
}

const getAssignBasedAsset = async (id) => {
  const response = await api.get(`${servicePrefix}/assigns/asset-log/${id}`)
  return response.data
}

const DratshangService = {
  getAllDratshangAsset,
  getDratshangAssetById,
  addDratshangAsset,
  updateDratshangAsset,
  deleteDratshangAsset,

  //Products
  getAllProduct,
  getProductById,
  addProduct,
  updateProduct,
  deleteProduct,
  getProduct,

  //Accessory
  getAllAccessory,
  getAccessoryById,
  addAccessory,
  updateAccessory,
  deleteAccessory,
  getAccessory,

  //Assigns
  getAllAssign,
  getAssign,
  getAssignById,
  addAssign,
  updateAssign,
  deleteAssign,
  getAssignBasedAccessory,
  addAssignAndUpdateAssetStatus,
  getAssignBasedAsset
}

export default DratshangService
