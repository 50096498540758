// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DratshangAssetService from '@services/dratshang-asset'

export const getAllProduct = createAsyncThunk('product/getAllProduct', async (params, { rejectWithValue }) => {
  try {
    const response = await DratshangAssetService.getAllProduct(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getProductById = createAsyncThunk('product/getProductById', async (id) => {
  const response = await DratshangAssetService.getProductById(id)
  return response
})

export const addProduct = createAsyncThunk('product/addProduct', async (product, { rejectWithValue }) => {
  try {
    const response = await DratshangAssetService.addProduct(product)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateProduct = createAsyncThunk('product/updateProduct', async (product, { rejectWithValue }) => {
  try {
    const response = await DratshangAssetService.updateProduct(product)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteProduct = createAsyncThunk('product/deleteProduct', async (id, { dispatch, getState }) => {
  await DratshangAssetService.deleteProduct(id)
  await dispatch(getAllProduct(getState()?.products.params))
  return id
})

export const productSlice = createSlice({
  name: 'products',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualProduct: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProduct.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.individualProduct = action.payload
        state.loading = false
      })
      .addCase(getProductById.pending, (state, action) => {
        state.individualProduct = action.payload
        state.loading = true
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.individualProduct = action.payload
        state.loading = false
      })
  }
})

export default productSlice.reducer
