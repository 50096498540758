import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

const getRoles = async (params) => {
  const response = await api.get(`${servicePrefix}/roles`, { params: stripEmptyObjects(params) })
  return response.data
}

const getRoleIndividual = async (id) => {
  const response = await api.get(`${servicePrefix}/roles/${id}`)
  return response.data
}

const getAllRoles = async () => {
  const response = await api.get(`${servicePrefix}/roles/all`)
  return response.data
}

const addRole = async (roles) => {
  const response = await api.post(`${servicePrefix}/roles`, roles)
  return response.data
}

const updateRoleIndividual = async (roles) => {
  const { id } = roles
  const response = await api.patch(`${servicePrefix}/roles/${id}`, roles)
  return response.data
}
const deleteRole = async (id) => {
  const response = await api.delete(`${servicePrefix}/roles/${id}`)
  return response.data
}

const RoleService = {
  getRoles,
  getRoleIndividual,
  addRole,
  updateRoleIndividual,
  getAllRoles,
  deleteRole
}

export default RoleService
