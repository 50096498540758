// ** Redux Imports
import KurimContributionService from '@services/kurim-contribution'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getKurimContribution = createAsyncThunk('kurimContribution/getKurimContribution', async (params) => {
  const response = await KurimContributionService.getKurimContribution(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    console.log(err.response.data)
    return rejectWithValue(err.response.data)
  }
})

export const getKurimContributionById = createAsyncThunk('kurimContribution/getKurimContributionById', async (id) => {
  const response = await KurimContributionService.getKurimContributionById(id)
  return response
})

export const addKurimContribution = createAsyncThunk('kurim/addKurimContribution', async (kurimContribution, { rejectWithValue }) => {
  try {
    const response = await KurimContributionService.addKurimContribution(kurimContribution)
    return response
  } catch (err) {
    console.log(err.response.data)
    return rejectWithValue(err.response.data)
  }
})

export const updateKurimContribution = createAsyncThunk('kurimContribution/updateKurimContribution', async (kurimContribution, { rejectWithValue }) => {
  console.log('kurimContribution', kurimContribution)
  try {
    const response = await KurimContributionService.updateKurimContribution(kurimContribution)
    console.log('res', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteKurimContribution = createAsyncThunk('kurimContribution/deleteKurimContribution', async (id, { dispatch, getState }) => {
  await KurimContributionService.deleteKurimContribution(id)
  await dispatch(getKurimContribution(getState()?.kurimContributions.params))
  return id
})

export const KurimContributionSlice = createSlice({
  name: 'kurim_contribution',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualKurimContribution: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKurimContribution.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getKurimContributionById.fulfilled, (state, action) => {
        state.individualKurimContribution = action.payload
        state.loading = false
      })
      .addCase(getKurimContributionById.pending, (state, action) => {
        state.individualKurimContribution = action.payload
        state.loading = true
      })
      .addCase(getKurimContributionById.rejected, (state, action) => {
        state.individualKurimContribution = action.payload
        state.loading = false
      })
  }
})

export default KurimContributionSlice.reducer
