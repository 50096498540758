// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DratshangService from '@services/dratshang'

export const getAllDratshang = createAsyncThunk('dratshang/getAllDratshang', async (params, { rejectWithValue }) => {
  try {
    const response = await DratshangService.getAllDratshang(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDratshangById = createAsyncThunk('dratshang/getDratshangById', async (id) => {
  const response = await DratshangService.getDratshangById(id)
  // console.log('dratshang store', response)
  return response
})

export const addDratshang = createAsyncThunk('dratshang/addDratshang', async (dratshang, { rejectWithValue }) => {
  try {
    const response = await DratshangService.addDratshang(dratshang)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDratshang = createAsyncThunk('dratshang/updateDratshang', async (dratshang, { rejectWithValue }) => {
  try {
    const response = await DratshangService.updateDratshang(dratshang)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteDratshang = createAsyncThunk('dratshang/deleteDratshang', async (id, { dispatch, getState }) => {
  await DratshangService.deleteDratshang(id)
  await dispatch(getAllDratshang(getState()?.dratshangs.params))
  return id
})

export const dratshangSlice = createSlice({
  name: 'dratshang',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDratshang: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllDratshang.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDratshangById.fulfilled, (state, action) => {
        state.individualDratshang = action.payload
        state.loading = false
      })
      .addCase(getDratshangById.pending, (state, action) => {
        state.individualDratshang = action.payload
        state.loading = true
      })
      .addCase(getDratshangById.rejected, (state, action) => {
        state.individualDratshang = action.payload
        state.loading = false
      })
  }
})

export default dratshangSlice.reducer
