// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/masters'

export const getDratshangLevel = createAsyncThunk('dratshangLevel/getDratshangLevel', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllDratshangLevel(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDratshangLevelById = createAsyncThunk('dratshangLevel/getDratshangLevelById', async (id) => {
  const response = await MasterService.getDratshangLevelById(id)
  return response
})

export const addDratshangLevel = createAsyncThunk('dratshangLevel/addDratshangLevel', async (dratshangLevel, { rejectWithValue }) => {
  try {
    const response = await MasterService.addDratshangLevel(dratshangLevel)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDratshangLevel = createAsyncThunk('dratshangLevel/updateDratshangLevel', async (dratshangLevel, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateDratshangLevel(dratshangLevel)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteDratshangLevel = createAsyncThunk('dratshangLevel/deleteDratshangLevel', async (id, { dispatch, getState }) => {
  await MasterService.deleteDratshangLevel(id)
  await dispatch(getDratshangLevel(getState()?.dratshangLevels.params))
  return id
})

export const dratshangLevelSlice = createSlice({
  name: 'dratshang_level',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDratshangLevel: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDratshangLevel.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDratshangLevelById.fulfilled, (state, action) => {
        state.individualDratshangLevel = action.payload
        state.loading = false
      })
      .addCase(getDratshangLevelById.pending, (state, action) => {
        state.individualDratshangLevel = action.payload
        state.loading = true
      })
      .addCase(getDratshangLevelById.rejected, (state, action) => {
        state.individualDratshangLevel = action.payload
        state.loading = false
      })
  }
})

export default dratshangLevelSlice.reducer
