// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
// import permissions from '@src/views/apps/roles-permissions/store'
import dratshangs from '@src/views/dratshang-information/store/index'
import dratshangAssets from '@src/views/dratshang-asset/asset/store/index'
import monkInformations from '@src/views/monk-information/monk/store'
import staffInformations from '@src/views/staff-information/staff/store'
import kurims from '@src/views/kurim/store'
import kurimContributions from '@src/views/kurim-contribution/store'
import monkExpenses from '@src/views/monk-expense/store/index'
import monkDesignations from '@src/views/monk-designation/store/index'
import tiens from '@src/views/tien/store/index'
import countries from '@src/views/master/country/store/index'
import dzongkhags from '@src/views/master/dzongkhag/store/index'
import gewogs from '@src/views/master/gewog/store/index'
import donationTypes from '@src/views/master/donation-type/store/index'
import feeTypes from '@src/views/master/fee-type/store/index'
import banks from '@src/views/master/bank/store/index'
import assetTypes from '@src/views/master/asset-type/store/index'
import checklistCategories from '@src/views/master/checklist-category/store/index'
import checklistItems from '@src/views/master/checklist-item/store/index'
import designations from '@src/views/master/designation/store/index'
import dratshangLevels from '@src/views/master/dratshang-level/store/index'
import grades from '@src/views/master/grade/store/index'
import gradeCategories from '@src/views/master/grade-category/store/index'
import memberships from '@src/views/membership/member/store'
import memberFees from '@src/views/collection-type/member-fee/store'
import donations from '@src/views/collection-type/donation/store'
import deputations from '@src/views/attendance/deputation/store'
import tours from '@src/views/attendance/tour/store'
import dailyAttendances from '@src/views/attendance/daily-attendance/store'
import leaves from '@src/views/attendance/leave/store'
import academicYears from '@src/views/academic-year/store'
import permissions from '@src/views/permission/store'
import roles from '@src/views/role/store'
import users from '@src/views/user/store'
import monkGrades from '@src/views/monk-grade/store'
import wallets from '@src/views/wallet/store'
import transactions from '@src/views/transaction/store'
import fileUploads from '@src/views/account-file-upload/store'
import products from '@src/views/dratshang-asset/product/store/index'
import accessories from '@src/views/dratshang-asset/accessory/store/index'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  dratshangs,
  dratshangAssets,
  countries,
  dzongkhags,
  gewogs,
  donationTypes,
  feeTypes,
  banks,
  assetTypes,
  designations,
  checklistCategories,
  checklistItems,
  grades,
  gradeCategories,
  dratshangLevels,
  monkInformations,
  staffInformations,
  kurims,
  kurimContributions,
  monkExpenses,
  monkDesignations,
  tiens,
  memberships,
  memberFees,
  donations,
  deputations,
  tours,
  dailyAttendances,
  leaves,
  academicYears,
  permissions,
  roles,
  users,
  monkGrades,
  wallets,
  transactions,
  fileUploads,
  products,
  accessories
}

export default rootReducer
