import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_API_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}`

const getAllAcademicYear = async (params) => {
  const response = await api.get(`${servicePrefix}/academic-years`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAcademicYearById = async (id) => {
  const response = await api.get(`${servicePrefix}/academic-years/${id}`)
  return response.data
}

const addAcademicYear = async (year) => {
  try {
    const response = await api.post(`${servicePrefix}/academic-years`, year)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const updateAcademicYear = async (year) => {
  const { id } = year
  const response = await api.patch(`${servicePrefix}/academic-years/${id}`, year)
  return response.data
}

const deleteAcademicYear = async (id) => {
  const response = await api.delete(`${servicePrefix}/academic-years/${id}`)
  return response.data
}

const AcademicService = {
  getAllAcademicYear,
  getAcademicYearById,
  addAcademicYear,
  updateAcademicYear,
  deleteAcademicYear
}

export default AcademicService
