// ** React Imports
import { lazy } from 'react'

const Login = lazy(() => import('../../views/authentication/Login'))
const LoginBasic = lazy(() => import('../../views/authentication/LoginBasic'))
const LoginCover = lazy(() => import('../../views/authentication/LoginCover'))

const Register = lazy(() => import('../../views/authentication/Register'))
const RegisterBasic = lazy(() => import('../../views/authentication/RegisterBasic'))
const RegisterCover = lazy(() => import('../../views/authentication/RegisterCover'))
const RegisterMultiSteps = lazy(() => import('../../views/authentication/register-multi-steps'))

const ForgotPassword = lazy(() => import('../../views/authentication/ForgotPassword'))
const ForgotPasswordBasic = lazy(() => import('../../views/authentication/ForgotPasswordBasic'))
const ForgotPasswordCover = lazy(() => import('../../views/authentication/ForgotPasswordCover'))

const ResetPasswordBasic = lazy(() => import('../../views/authentication/ResetPasswordBasic'))
const ResetPasswordCover = lazy(() => import('../../views/authentication/ResetPasswordCover'))

const VerifyEmailBasic = lazy(() => import('../../views/authentication/VerifyEmailBasic'))
const VerifyEmailCover = lazy(() => import('../../views/authentication/VerifyEmailCover'))

const TwoStepsBasic = lazy(() => import('../../views/authentication/TwoStepsBasic'))
const TwoStepsCover = lazy(() => import('../../views/authentication/TwoStepsCover'))

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <LoginBasic />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/forgot-password-basic',
    element: <ForgotPasswordBasic />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/forgot-password-cover',
    element: <ForgotPasswordCover />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/reset-password-basic',
    element: <ResetPasswordBasic />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/reset-password-cover',
    element: <ResetPasswordCover />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/verify-email-basic',
    element: <VerifyEmailBasic />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/verify-email-cover',
    element: <VerifyEmailCover />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/two-steps-basic',
    element: <TwoStepsBasic />,
    meta: {
      layout: 'blank'
    }
  },
  {
    path: '/two-steps-cover',
    element: <TwoStepsCover />,
    meta: {
      layout: 'blank'
    }
  }
]

export default AuthenticationRoutes
