// ** Icons Import
import { Menu, EyeOff, Folder, LifeBuoy, Shield, ArrowRight, Grid } from 'react-feather'

export default [
  {
    id: 'dratshang management',
    title: 'Manage Dratshangs',
    icon: <Grid size={20} />,
    children: [
      {
        id: 'manage-dratshang',
        title: 'Manage Dratshang',
        icon: <ArrowRight size={20} />,
        children: [
          {
            id: 'add-dratshang',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/dratshang/add',
            resource: 'Dratshang'
          },
          {
            id: 'dratshang-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/dratshang/lists',
            action: 'read',
            resource: 'Dratshang'
          },
          {
            id: 'dratshang-search',
            title: 'Search Dratshang',
            icon: <ArrowRight size={20} />,
            navLink: '/dratshang/search',
            resource: 'Dratshang'
          }
        ]
      },
      {
        id: 'manage-monk',
        title: 'Manage Monk',
        icon: <ArrowRight size={20} />,
        resource: 'Monk',
        children: [
          {
            id: 'add-monk',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-information/add',
            action: 'create',
            resource: 'Monk'
          },
          {
            id: 'monk-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-information/lists',
            action: 'read',
            resource: 'Monk'
          },
          {
            id: 'monk-search',
            title: 'Search Monk',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-information/search',
            action: 'read',
            resource: 'Monk'
          }
        ]
      },
      {
        id: 'manage-employee',
        title: 'Manage Employee',
        icon: <ArrowRight size={20} />,
        children: [
          {
            id: 'add-employee',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/employee/add',
            resource: 'Staff'
          },
          {
            id: 'employee-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/employee/lists',
            action: 'read',
            resource: 'Staff'
          },
          {
            id: 'employee-search',
            title: 'Search Employee',
            icon: <ArrowRight size={20} />,
            navLink: '/employee/search',
            resource: 'Staff'
          }
        ]
      },
      {
        id: 'manage-designation',
        title: 'Manage Designation',
        icon: <ArrowRight size={20} />,
        resource: 'MonkDesignation',
        children: [
          {
            id: 'add-designation',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-designation/monk-search',
            action: 'create',
            resource: 'MonkDesignation'
          },
          {
            id: 'designation-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-designation/lists',
            action: 'read',
            resource: 'MonkDesignation'
          }
        ]
      },
      {
        id: 'manage-tiens',
        title: 'Manage Tiens',
        icon: <ArrowRight size={20} />,
        resource: 'Tien',
        children: [
          {
            id: 'add-tien',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/tien/monk-search',
            action: 'create',
            resource: 'Tien'
          },
          {
            id: 'tien-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/tien/lists',
            action: 'read',
            resource: 'Tien'
          }
        ]
      },
      {
        id: 'manage-grade',
        title: 'Manage Grade',
        icon: <ArrowRight size={20} />,
        resource: 'Grade',
        children: [
          {
            id: 'add-grade',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-grade/add',
            action: 'create',
            resource: 'Grade'
          },
          // {
          //   id: 'grade-list',
          //   title: 'List',
          //   icon: <ArrowRight size={20} />,
          //   navLink: '/monk-grade/lists',
          //   resource: 'Enrollments'
          // },
          {
            id: 'view',
            title: 'View',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-grade/view',
            action: 'read',
            resource: 'Grade'
          }
        ]
      },
      {
        id: 'wallet',
        title: 'Wallet',
        icon: <ArrowRight size={20} />,
        resource: 'Wallet',
        children: [
          {
            id: 'Main',
            title: 'Main Wallet',
            icon: <ArrowRight size={12} />,
            navLink: '/main-wallet',
            action: 'manage',
            resource: 'MainWallet'
          },
          {
            id: 'list',
            title: 'List',
            icon: <ArrowRight size={12} />,
            navLink: '/wallet/list',
            action: 'read',
            resource: 'Wallet'
          },
          {
            id: 'transaction-list',
            title: 'Transaction',
            icon: <ArrowRight size={12} />,
            navLink: '/transaction',
            action: 'read',
            resource: 'Transaction'
          }
        ]
      },
      {
        id: 'manage-expense',
        title: 'Manage Expense',
        icon: <ArrowRight size={20} />,
        resource: 'Expense',
        children: [
          {
            id: 'add-expense',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-expense/monk-search',
            action: 'create',
            resource: 'Expense'
          },
          {
            id: 'monk-expense-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/monk-expense/lists',
            action: 'read',
            resource: 'Expense'
          }
        ]
      },
      {
        id: 'daily-attendance',
        title: 'Daily Attendance',
        icon: <ArrowRight size={20} />,
        resource: 'DailyAttendence',
        children: [
          {
            id: 'add-daily-attendance',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/daily-attendance/monk-search',
            action: 'create',
            resource: 'DailyAttendence'
          },
          {
            id: 'daily-attendance-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/daily-attendance/lists',
            action: 'read',
            resource: 'DailyAttendence'
          }
        ]
      },
      {
        id: 'leave',
        title: 'Leave',
        icon: <ArrowRight size={20} />,
        resource: 'Leave',
        children: [
          {
            id: 'add-leave',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/leave/monk-search',
            action: 'create',
            resource: 'Leave'
          },
          {
            id: 'leave-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/leave/lists',
            action: 'read',
            resource: 'Leave'
          }
        ]
      },
      {
        id: 'deputation',
        title: 'Deputation',
        icon: <ArrowRight size={20} />,
        resource: 'Deputation',
        children: [
          {
            id: 'add-deputation',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/deputation/monk-search',
            action: 'create',
            resource: 'Deputation'
          },
          {
            id: 'deputation-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/deputation/lists',
            action: 'read',
            resource: 'Deputation'
          }
        ]
      },
      {
        id: 'tour',
        title: 'Tour',
        icon: <ArrowRight size={20} />,
        resource: 'Tour',
        children: [
          {
            id: 'add-tour',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/tour/monk-search',
            action: 'create',
            resource: 'Tour'
          },
          {
            id: 'tour-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/tour/lists',
            action: 'read',
            resource: 'Tour'
          }
        ]
      },
      {
        id: 'manage-kurim',
        title: 'Manage Kurim',
        icon: <ArrowRight size={20} />,
        resource: 'Kurim',
        children: [
          {
            id: 'add-kurim',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/kurim/add',
            action: 'create',
            resource: 'Kurim'
          },
          {
            id: 'kurim-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/kurim/lists',
            action: 'read',
            resource: 'Kurim'
          }
        ]
      },
      {
        id: 'manage-kurim-contribution',
        title: 'Kurim Contribution',
        icon: <ArrowRight size={20} />,
        resource: 'KurimContribution',
        children: [
          {
            id: 'add-kurim-contribution',
            title: 'Add',
            icon: <ArrowRight size={20} />,
            navLink: '/kurim-contribution/search-monk',
            action: 'create',
            resource: 'KurimContribution'
          },
          {
            id: 'kurim-contribution-list',
            title: 'List',
            icon: <ArrowRight size={20} />,
            navLink: '/kurim-contribution/lists',
            action: 'read',
            resource: 'KurimContribution'
          }
        ]
      },
      {
        id: 'dratshang-asset',
        title: 'Dratshang Asset',
        icon: <ArrowRight size={20} />,
        resource: 'Asset',
        children: [
          {
            id: 'product',
            title: 'Product',
            icon: <ArrowRight size={20} />,
            resource: 'Asset',
            children: [
              {
                id: 'add-product',
                title: 'Add',
                icon: <ArrowRight size={20} />,
                navLink: '/product/add',
                action: 'create',
                resource: 'Asset'
              },
              {
                id: 'product-list',
                title: 'List',
                icon: <ArrowRight size={20} />,
                navLink: '/product/lists',
                action: 'read',
                resource: 'Asset'
              }
            ]
          },
          {
            id: 'asset',
            title: 'Asset',
            icon: <ArrowRight size={20} />,
            resource: 'Asset',
            children: [
              {
                id: 'add-asset',
                title: 'Add',
                icon: <ArrowRight size={20} />,
                navLink: '/dratshang-asset/add',
                action: 'create',
                resource: 'Asset'
              },
              {
                id: 'asset-list',
                title: 'List',
                icon: <ArrowRight size={20} />,
                navLink: '/dratshang-asset/lists',
                action: 'read',
                resource: 'Asset'
              }
            ]
          },
          {
            id: 'accessory',
            title: 'Accessory',
            icon: <ArrowRight size={20} />,
            resource: 'Asset',
            children: [
              {
                id: 'add-accessory',
                title: 'Add',
                icon: <ArrowRight size={20} />,
                navLink: '/accessory/add',
                action: 'create',
                resource: 'Asset'
              },
              {
                id: 'accessory-list',
                title: 'List',
                icon: <ArrowRight size={20} />,
                navLink: '/accessory/lists',
                action: 'read',
                resource: 'Asset'
              }
            ]
          }
        ]
      },
      {
        id: 'manage-administration',
        title: 'Manage Administration',
        icon: <ArrowRight size={20} />,
        resource: 'Dashboard',
        children: []
      }
    ]
  },
  {
    id: 'lhabang-management',
    title: 'Lhabang Management',
    icon: <Grid size={20} />,
    resource: 'Members',
    children: [
      {
        id: 'mangae-member',
        title: 'Manage Lhabang Members',
        icon: <ArrowRight size={20} />,
        resource: 'Members',
        children: [
          {
            id: 'add-member',
            title: 'Add',
            icon: <ArrowRight size={12} />,
            navLink: '/member/add',
            resource: 'Members'
          },
          {
            id: 'list-member',
            title: 'List',
            icon: <ArrowRight size={12} />,
            navLink: '/member/lists',
            resource: 'Members'
          },
          {
            id: 'search-member',
            title: 'Search Member',
            icon: <ArrowRight size={12} />,
            navLink: '/member/search',
            resource: 'Members'
          }
        ]
      },
      {
        id: 'mangae-collection',
        title: 'Manage Collection',
        icon: <ArrowRight size={20} />,
        resource: 'Members',
        children: [
          {
            id: 'membership-fee',
            title: 'Member Fee',
            icon: <ArrowRight size={12} />,
            resource: 'Members',
            children: [
              {
                id: 'add-membership-fee',
                title: 'Add',
                icon: <ArrowRight size={12} />,
                navLink: '/collection/member-fee/member-search',
                resource: 'Members'
              },
              {
                id: 'membership-fee-list',
                title: 'List',
                icon: <ArrowRight size={12} />,
                navLink: '/collection/member-fee/lists',
                resource: 'Members'
              }
            ]
          },
          {
            id: 'donation',
            title: 'Donation',
            icon: <ArrowRight size={12} />,
            resource: 'Members',
            children: [
              {
                id: 'add-donation',
                title: 'Add',
                icon: <ArrowRight size={12} />,
                navLink: '/collection/donation/donation-type',
                resource: 'Members'
              },
              {
                id: 'donation-list',
                title: 'List',
                icon: <ArrowRight size={12} />,
                navLink: '/collection/donation/lists',
                resource: 'Members'
              }
            ]
          }
        ]
      },
      {
        id: 'account',
        title: 'Accounts',
        icon: <ArrowRight size={12} />,
        resource: 'Members',
        navLink: '/account-file-upload'
      }
    ]
  }
]
