import { lazy } from 'react'

const MonkDesignationList = lazy(() => import('../../views/monk-designation/list/index'))
const SearchMonk = lazy(() => import('../../views/monk-designation/add/index'))
const AddMonkDesignation = lazy(() => import('../../views/monk-designation/add/AddMonkDesignation'))
const ViewMonkDesignation = lazy(() => import('../../views/monk-designation/view/index'))
const EditMonkDesignation = lazy(() => import('../../views/monk-designation/edit/index'))

const DesignationRoutes = [
  {
    path: '/monk-designation/monk-search',
    element: <SearchMonk />,
    meta: {
      action: 'create',
      resource: 'MonkDesignation'
    }
  },
  {
    path: '/monk-designation/monk-search/add',
    element: <AddMonkDesignation />,
    meta: {
      action: 'create',
      resource: 'MonkDesignation'
    }
  },
  {
    path: '/monk-designation/lists',
    element: <MonkDesignationList />,
    meta: {
      action: 'read',
      resource: 'MonkDesignation'
    }
  },
  {
    path: '/monk-designation/lists/view/:id',
    element: <ViewMonkDesignation />,
    meta: {
      action: 'read',
      resource: 'MonkDesignation'
    }
  },
  {
    path: '/monk-designation/lists/edit/:id',
    element: <EditMonkDesignation />,
    meta: {
      action: 'update',
      resource: 'MonkDesignation'
    }
  }
]

export default DesignationRoutes
