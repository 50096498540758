import { lazy } from 'react'

// const MonkGradeList = lazy(() => import('../../views/monk-grade/list/index'))
const AddMonkGrade = lazy(() => import('../../views/monk-grade/add/index'))
// const ViewMonkGrade = lazy(() => import('../../views/monk-grade/view/index'))
const ViewGrade = lazy(() => import('../../views/monk-grade/view/index'))
const ViewGradeResponse = lazy(() => import('../../views/monk-grade/view/GradeInformation'))
const EditMonkGrade = lazy(() => import('../../views/monk-grade/edit/index'))
// const AddMoreMonk = lazy(() => import('../../views/monk-grade/add-more-monk/index'))

const MonkGradeRoutes = [
  {
    path: '/monk-grade/add',
    element: <AddMonkGrade />,
    meta: {
      action: 'create',
      resource: 'Grade'
    }
  },
  // {
  //   path: '/monk-grade/lists',
  //   element: <MonkGradeList />,
  //   meta: {
  //     action: 'read',
  //     resource: 'Grade'
  //   }
  // },

  {
    path: '/monk-grade/view/:id/edit',
    element: <EditMonkGrade />,
    meta: {
      action: 'update',
      resource: 'Grade'
    }
  },
  // {
  //   path: '/monk-grade/lists/add-more-monk/:id',
  //   element: <AddMoreMonk />,
  //   meta: {
  //     action: 'create',
  //     resource: 'Grade'
  //   }
  // },
  // {
  //   path: '/monk-grade/lists/view/:id',
  //   element: <ViewMonkGrade />,
  //   meta: {
  //     action: 'read',
  //     resource: 'Grade'
  //   }
  // },
  {
    path: '/monk-grade/view',
    element: <ViewGrade />,
    meta: {
      action: 'read',
      resource: 'Grade'
    }
  },
  {
    path: '/monk-grade/view/monk-list',
    element: <ViewGradeResponse />,
    meta: {
      action: 'read',
      resource: 'Grade'
    }
  }
]

export default MonkGradeRoutes
