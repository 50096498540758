// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '@services/masters'

export const getAssetType = createAsyncThunk('assetType/getAssetType', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllAssetType(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAssetTypeById = createAsyncThunk('assetType/getAssetTypeById', async (id) => {
  const response = await MasterService.getAssetTypeById(id)
  return response
})

export const addAssetType = createAsyncThunk('assetType/addAssetType', async (assetType, { rejectWithValue }) => {
  try {
    const response = await MasterService.addAssetType(assetType)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateAssetType = createAsyncThunk('assetType/updateAssetType', async (assetType, { rejectWithValue }) => {
  try {
    const response = await MasterService.updateAssetType(assetType)
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteAssetType = createAsyncThunk('assetType/deleteAssetType', async (id, { dispatch, getState }) => {
  await MasterService.deleteAssetType(id)
  await dispatch(getAssetType(getState()?.assetTypes.params))
  return id
})

export const assetTypeSlice = createSlice({
  name: 'asset_type',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualAssetType: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetType.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAssetTypeById.fulfilled, (state, action) => {
        state.individualAssetType = action.payload
        state.loading = false
      })
      .addCase(getAssetTypeById.pending, (state, action) => {
        state.individualAssetType = action.payload
        state.loading = true
      })
      .addCase(getAssetTypeById.rejected, (state, action) => {
        state.individualAssetType = action.payload
        state.loading = false
      })
  }
})

export default assetTypeSlice.reducer
