// ** Redux Imports
import AttendenceService from '@services/attendance'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const getAllTour = createAsyncThunk('tour/getAllTour', async (params, { rejectWithValue }) => {
  try {
    const response = await AttendenceService.getAllTour(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getTourById = createAsyncThunk('tour/getTourById', async (id) => {
  const response = await AttendenceService.getTourById(id)
  return response
})

export const addTour = createAsyncThunk('tour/addTour', async (tour, { rejectWithValue }) => {
  try {
    const response = await AttendenceService.addTour(tour)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateTour = createAsyncThunk('tour/updateTour', async (tour) => {
  const response = await AttendenceService.updateTour(tour)
  return response
})

export const deleteTour = createAsyncThunk('tour/deleteTour', async (id, { dispatch, getState }) => {
  await AttendenceService.deleteTour(id)
  await dispatch(getAllTour(getState()?.tours.params))
  return id
})

export const TourSlice = createSlice({
  name: 'tour',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualTour: null,
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTour.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTourById.fulfilled, (state, action) => {
        state.individualTour = action.payload
        state.loading = false
      })
      .addCase(getTourById.pending, (state, action) => {
        state.individualTour = action.payload
        state.loading = true
      })
      .addCase(getTourById.rejected, (state, action) => {
        state.individualTour = action.payload
        state.loading = false
      })
  }
})

export default TourSlice.reducer
